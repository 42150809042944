import type {
  MemberAppointmentHomicidalRiskAssessment,
  ProtocolTypes,
} from "../types";

// Enums
enum SessionTypes {
  DIAGNOSTIC_ASSESSMENT = "*OCD DIAGNOSTIC ASSESSMENT",
  THERAPY_SESSION = "Therapy Session",
  FIRST_THERAPY_SESSION = "First Therapy Session",
  MID_THERAPY_SESSION = "Mid Therapy Session",
  THERAPY_CHECK_IN = "Therapy Check In",
  LOC_THERAPY_CHECK_IN = "LOC THERAPY CHECK-IN",
  SUPPORT_CHECK_IN = "Support Check In",
  THREE_MONTH_SUPPORT_CHECK_IN = "3 Month Support Check In",
  SIX_MONTH_SUPPORT_CHECK_IN = "6 Month Support Check In",
  NINE_MONTH_SUPPORT_CHECK_IN = "9 Month Support Check In",
  TWELVE_MONTH_SUPPORT_CHECK_IN = "12 Month Support Check In",
  FORMS_PLACEHOLDER = "Forms Placeholder",
}

enum SessionTypesV2 {
  INITIAL_ASSESSMENT = "Initial Assessment",
  FOLLOWUP_ASSESSMENT = "Followup Assessment",
  THERAPY_SESSION = "Therapy Session",
  THERAPY_SESSION_WITH_FORMS = "Therapy Session w/ Forms",
  RESTART_DIAGNOSTIC_ASSESSMENT = "Reassessment",
  FORMS_PLACEHOLDER = "Forms Placeholder",
}

export enum SessionTypesV3 {
  FIRST_SESSION = "First Session",
  SECOND_SESSION = "Second Session",
  THERAPY_SESSION = "Therapy Session",
  REASSESSMENT = "Reassessment",
  NONMEMBER_EDUCATION_SESSION = "Non-member Education Session",
}

export type AllSessionTypes = SessionTypes | SessionTypesV2 | SessionTypesV3;

export enum AppointmentStatuses {
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  SCHEDULED = "scheduled",
  PENDING = "pending",
}

export enum MemberGoalFilterState {
  ALL = "All",
  OPEN = "Open",
  COMPLETED = "Completed",
}

export enum SupervisorAppointmentNoteReviewStatus {
  PENDING_CHANGES = "pending changes",
  PENDING_REVIEW = "pending review",
  APPROVED = "approved",
}

export interface Appointment {
  "premium_users.protocol_type": ProtocolTypes;
  active_subscription: number;
  admd_visit_id: string;
  amd_appt_backfill: string;
  appointment_date: string;
  appointment_state: AppointmentStatuses;
  appointment_type: string;
  appt_created_at: string;
  autoinvoice_id: string;
  bg_address_setter: string;
  bg_diagnostics_id: string;
  bg_followup_changes_to_doctor_visits: string;
  bg_followup_changes_to_doctor_visits_text: string;
  bg_followup_changes_to_medication: string;
  bg_followup_changes_to_medication_text: string;
  bg_reported_current_address: string;
  billing_complete: string;
  billing_state: string;
  booked_author: string;
  cancellation_author: string;
  cancellation_info: string;
  cancellation_reason: string;
  cancellation_reason_detail: string;
  cancellation_ts: string;
  cancelled_via_reschedule: string;
  cancelled_waive_late_fee: number;
  cancelled_waive_late_fee_reason: string;
  cancelled_waive_late_fee_reason_notes: string;
  cancelled_within_24_hours: number;
  clinical_forms_dass21_id: string;
  clinical_forms_diamond_adult_id: string;
  clinical_forms_diamond_youth_id: string;
  clinical_forms_docs_id: string;
  clinical_forms_qles_id: string;
  clinician_compensated: string;
  clinician_email: string;
  clinician_signed_email: string;
  clinician_timezone: string;
  clinician_image_id: string | null;
  completion_at: string;
  completion_info: string;
  created_at: string;
  dass_anxiety: string;
  dass_depression: string;
  dass_stress: string;
  diagnosed_with_ocd: string;
  diagnostic_timezone: string;
  diamond_distress_score: string;
  diamond_functional_score: string;
  diamond_severity_score: string;
  discharge: string;
  docs_severity: string;
  end_time_in_iso8601_utc: string;
  end_ts: string;
  event_id: string;
  first_name: string;
  gcal_id: string;
  has_forms: boolean;
  has_scores: string;
  id: number;
  is_test_user: string;
  last_name: string;
  medical_additional_comments: string;
  medical_json: string;
  medical_within_normal_limits: string | number;
  member_takeaway_notes: string;
  member_user_id: string;
  needs_audit: string;
  needs_billing_review: string;
  needs_billing_review_notes: string;
  needs_clinical_review: string;
  needs_clinical_review_notes: string;
  needs_review_notes: string;
  needs_service_team_to_reschedule: number;
  needs_service_team_to_reschedule_status: string;
  needs_supervisor_review: number;
  next_step: string;
  notes: string;
  notify_via_email: string;
  override_cpt: string;
  override_cpt_by: string;
  protocol_type: string;
  qles: string;
  rescheduled_at: string;
  rescheduled_info: string;
  rescheduled_review_notes: string;
  rescheduled_signed_email: string;
  rescheduled_to: string;
  risk_assessment_json: string;
  session_length: string;
  session_type: SessionTypes | SessionTypesV2 | SessionTypesV3;
  start_time_in_iso8601_utc: string;
  start_ts: string;
  state: string;
  successfully_completed_and_billable: string;
  summary_appropriate_for_nocd: string;
  provisional_referral_needed: string;
  summary_cpt: string;
  summary_end_time: string;
  summary_icd_json: string[];
  summary_not_continuing_nocd_plan: string;
  summary_not_continuing_nocd_text: string;
  summary_present_json: string;
  summary_present_other: string;
  summary_private_notes: string;
  summary_start_time: string;
  summary_treatment_goals: string[];
  summary_treatment_progress: string;
  summary_treatment_response: string;
  summary_types_of_treatment_erp: number;
  summary_types_of_treatment_other: string;
  user_id: number;
  user_email: string;
  zoom_id: string;
  zoom_join_link: string;
  zoom_password: string;
  dynamic_forms: {
    appointment_id: number;
    completed_at: string;
    created_at: string;
    deleted_by: string;
    deleted_linked_appointment_id: number;
    form_fields_data: { [key: string]: number | string };
    form_id: number;
    form_scores_data: { [key: string]: number | string };
    id: number;
    is_completed: number;
    is_deleted: number;
    name: string;
    updated_at: string;
  }[];
  supervisor_review_status?: SupervisorAppointmentNoteReviewStatus | null;
  can_restore_cancelled: number;
  can_restore_completed: number;
  review_requested_at: string;
  final_appointment_confirmation?: number | null;
  send_cancel_follow_up?: number | null;
  primary_icd_code?: string;
  secondary_icd_codes?: string[] | null;
  homicidal_risk_assessment?: MemberAppointmentHomicidalRiskAssessment | null;
  sched_protocol_compliant?: boolean;
}

export interface SupervisorReviewNote {
  id?: number;
  user_id: number;
  note_text: string;
  clinician_email: string;
  member_appointments_id: number;
  created_at?: string;
  is_deleted?: number;
}
