import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import {
  BurstMode as BurstModeIcon,
  People as PeopleIcon,
  WorkspacePremium as WorkspacePremiumIcon,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import { isEmpty } from "lodash";
import { useQuery, UseQueryResult } from "react-query";

import { ISimpleMetric } from "../../types";
import SimpleMilestoneMetric from "./SimpleMilestoneMetric";

const useClinicianTreatmentConquerorsMetric = (
  clinicianEmail: string,
  timePeriod = "quarter"
): UseQueryResult<ISimpleMetric, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "clinician-current-stats",
      "treatment-conquerors",
      "2025-q1",
      timePeriod,
      clinicianEmail,
    ],
    ({ signal }) =>
      api
        .get<ISimpleMetric>(
          `/v1/clinicians/${clinicianEmail}/progress/2025/q1/current_stats/treatment_conquerors`,
          {
            headers: {
              Authorization: accessToken,
            },
            params: {
              period: timePeriod,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken && !!clinicianEmail,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

export const useAllTimeHoursMetric = (
  clinicianEmail: string
): UseQueryResult<ISimpleMetric, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    ["clinician-current-stats", "all-time-hours", "2025-q1", clinicianEmail],
    ({ signal }) =>
      api
        .get<ISimpleMetric>(
          `/v1/clinicians/${clinicianEmail}/progress/2025/q1/current_stats/all_time_hours`,
          {
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken && !!clinicianEmail,
      staleTime: Infinity,
    }
  );
};

const useAllTimeMembersMetric = (
  clinicianEmail: string
): UseQueryResult<ISimpleMetric, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "clinician-current-stats",
      "all-time-member-metric",
      "2025-q1",
      clinicianEmail,
    ],
    ({ signal }) =>
      api
        .get<ISimpleMetric>(
          `/v1/clinicians/${clinicianEmail}/progress/2025/q1/current_stats/all_time_members`,
          {
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken && !!clinicianEmail,
      staleTime: Infinity,
    }
  );
};

const MilestoneMetrics = ({ clinicianEmail }: { clinicianEmail: string }) => {
  const {
    data: allTimeCompletedData,
    isLoading: isLoadingAllTimeCompleted,
    error: allTimeCompletedError,
  } = useAllTimeHoursMetric(clinicianEmail);

  const {
    data: allTimeMembersData,
    isLoading: isLoadingAllTimeMembers,
    error: allTimeMembersError,
  } = useAllTimeMembersMetric(clinicianEmail);

  const {
    data: allConquerorsData,
    isLoading: isLoadingAllConquerors,
    error: allConquerorsError,
  } = useClinicianTreatmentConquerorsMetric(clinicianEmail, "all-time");

  if (
    isLoadingAllTimeCompleted ||
    isLoadingAllTimeMembers ||
    isLoadingAllConquerors
  ) {
    return <SimpleMilestoneMetric.Loading />;
  }

  if (allTimeCompletedError || allTimeMembersError || allConquerorsError) {
    return <SimpleMilestoneMetric.Failure message="Failed to load metrics." />;
  }

  if (
    isEmpty(allTimeCompletedData) ||
    isEmpty(allTimeMembersData) ||
    isEmpty(allConquerorsData)
  ) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <SimpleMilestoneMetric
        icon={<PeopleIcon style={{ width: "24px", height: "24px" }} />}
        value={allTimeMembersData.value}
        text="Total NOCD Members"
        unit={allTimeMembersData.unit}
      />
      <SimpleMilestoneMetric
        icon={
          <WorkspacePremiumIcon style={{ width: "24px", height: "24px" }} />
        }
        value={allConquerorsData.value}
        text="Total Conquerors to date"
        unit={allConquerorsData.unit}
      />
      <SimpleMilestoneMetric
        icon={<BurstModeIcon style={{ width: "24px", height: "24px" }} />}
        value={allTimeCompletedData.value}
        text="Total completed hours to date"
        unit={allTimeCompletedData.unit}
      />
    </Stack>
  );
};

export default MilestoneMetrics;
