import { useSession } from "@core/hooks/useSession";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";

export enum NotificationType {
  MEMBER_COMPLETED_ASSESSMENT = "member_completed_assessment",
  MEMBER_COMPLETED_JOURNAL_ENTRY = "member_completed_journal_entry",
  MEMBER_COMPLETED_ROI = "member_completed_roi",
  MEMBER_COMPLETED_REVIEW = "member_completed_review",
  MEMBER_COMPLETED_CONSENT_FORM = "member_completed_consent_form",
  MEMBER_COMPLETED_NPP_FORM = "member_completed_npp_form",
  MEMBER_USED_SOS = "member_used_sos",
  MEMBER_LEFT_EXPOSURE_PRACTICE_NOTE = "member_left_exposure_practice_note",
  MEMBER_COMPLETED_EXPOSURE_PRACTICE = "member_completed_exposure_practice",
  APPOINTMENT_SCHEDULED = "appointment_scheduled",
  APPOINTMENT_RESCHEDULED = "appointment_rescheduled",
  APPOINTMENT_CANCELLED = "appointment_cancelled",
  LATE_RESPONSE_MESSAGE_REMINDER = "late_response_message_reminder",
  POST_FIRST_SESSION_MESSAGE_REMINDER = "post_first_session_message_reminder",
  BIRTHDAY_MESSAGE_REMINDER = "birthday_message_reminder",
  TREATMENT_MILESTONE_LETTER_REMINDER = "treatment_milestone_letter_reminder",
  MEMBER_REMINDER = "member_reminder",
  MEMBER_MILESTONE_FIVE_DAY_WARNING = "member_milestone_five_day_reminder",
  MEMBER_SNOOZED_MILESTONE_WEEKLY_REMINDER = "member_snoozed_milestone_weekly_reminder",
  MEMBER_AUTO_MILESTONE_LETTER_SENT = "member_auto_milestone_letter_sent",
  AI_NEW_MEMBER_EVENT = "ai_new_member_event",
  MEMBER_SET_PERSONAL_GOAL = "member_set_personal_goal",
}

export interface Notification {
  id: number;
  ehr_user_id: number;
  title: string;
  description: string | null;
  type: NotificationType;
  payload: unknown;
  member_user_id: number | null;
  member_name: string | null;
  member_avatar_url: string | null;
  is_birthday?: boolean | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

interface MyNotifications {
  notifications: Notification[];
  current_page: number;
  total_pages: number;
  total_count: number;
  next_page: number | null;
}

const getMyNotifications = (
  page: number,
  limit: number,
  accessToken: string,
  signal: AbortSignal
) => {
  return api
    .get<MyNotifications>("/v1/ehr/notifications", {
      headers: {
        Authorization: accessToken,
      },
      params: {
        page,
        limit,
      },
      signal,
    })
    .then(({ data }) => data)
    .catch(transformAPIError);
};

const useMyNotifications = (
  limit = 100
): UseInfiniteQueryResult<MyNotifications, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useInfiniteQuery(
    ["ehr-notifications", accessToken, limit],
    ({ signal, pageParam }) =>
      getMyNotifications(pageParam as number, limit, accessToken, signal),
    {
      getNextPageParam: (lastPage) => lastPage.next_page,
      enabled: !!accessToken,
    }
  );
};

export default useMyNotifications;
