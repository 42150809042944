import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Link from "next/link";
import React from "react";

import { impactBackBadgeUrls, impactFrontBadgeUrls } from "../utils";
import ImpactBadge from "./ImpactBadge";

const ImpactDialogue = ({
  allTimeMembersValue,
  allConquerorsValue,
  allTimeCompletedValue,
  allActiveMembersValue,
  onClose,
  isOpen,
}: {
  allTimeMembersValue: number;
  allConquerorsValue: number;
  allActiveMembersValue: number;
  allTimeCompletedValue: number | string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const badgeTitles = [
    {
      title: (
        <>
          LIFE CHANGING
          <br />
          OUTCOMES
        </>
      ),
      url: impactFrontBadgeUrls.lifeChangingOutcomes,
      backOfBadgeUrl: impactBackBadgeUrls.lifeChangingOutcomes,
      count: allConquerorsValue || 0,
    },
    {
      title: (
        <>
          ACTIVELY ENGAGED
          <br />
          MEMBERS
        </>
      ),
      url: impactFrontBadgeUrls.consistentTreatment,
      backOfBadgeUrl: impactBackBadgeUrls.consistentTreatment,
      count: allActiveMembersValue || 0,
    },
    {
      title: (
        <>
          HELPED ON THEIR
          <br />
          THERAPY JOURNEY
        </>
      ),
      url: impactFrontBadgeUrls.helpedJourney,
      backOfBadgeUrl: impactBackBadgeUrls.helpedJourney,
      count: allTimeMembersValue || 0,
    },
    {
      title: (
        <>
          DIRECT
          <br />
          THERAPY HOURS
        </>
      ),
      url: impactFrontBadgeUrls.therapyHours,
      backOfBadgeUrl: impactBackBadgeUrls.therapyHours,
      count: allTimeCompletedValue || "0",
    },
  ];

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
        "& .MuiPaper-root": {
          top: "40px",
          borderRadius: "16px",
          overflow: "hidden",
        },
      }}
    >
      <Box
        width="100%"
        sx={{
          position: "relative",
          background: `linear-gradient(171deg, rgba(255, 255, 255, 0.00) 6.65%, #FFF 33.34%), linear-gradient(90deg, #DAC0FE 0%, #FCE2B6 45.78%, #AEE2E6 91.55%), #FFF`,
          zIndex: 0,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={1}
          sx={{ height: "100%" }}
        >
          <Stack
            direction="row"
            justifyContent="start"
            alignItems="center"
            sx={{ flex: 2, padding: "16px" }}
          >
            <Stack
              sx={{
                padding: "10px",
                gap: 1,
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                height: "100%",
                width: "240px",
                marginTop: "40px",
              }}
            >
              <Typography
                variant="body2"
                fontWeight="bold"
                style={{
                  fontSize: "12px",
                  lineHeight: "16.8px",
                  letterSpacing: "0.96px",
                  marginBottom: "4px",
                }}
              >
                KEEP GOING!
              </Typography>
              <Divider
                sx={{
                  width: "188px",
                  background: `linear-gradient(to right, #8E93F1 0%, #00A3AD 100%)`,
                  height: "2px",
                  mb: 1,
                }}
              />
              <Typography
                variant="body2"
                fontWeight="bold"
                style={{
                  fontSize: "14px",

                  lineHeight: "19.6px",
                  letterSpacing: "1.12px",
                }}
              >
                YOU&apos;RE CHANGING LIVES
              </Typography>

              <Box mt={4}>
                <Link href="/progress" passHref>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onClose()}
                    sx={{ fontSize: "12px" }}
                  >
                    View details
                  </Button>
                </Link>
              </Box>
            </Stack>
            {badgeTitles.map((badge) => (
              <ImpactBadge
                key={badge.url}
                frontUrl={badge.url}
                backUrl={badge.backOfBadgeUrl}
                title={badge.title}
                count={badge.count}
              />
            ))}
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default ImpactDialogue;
