import {
  Notification,
  NotificationType,
} from "@core/services/nocd-api/notifications/queries/useMyNotifications";
import { MemberChartPageTabs } from "@core/types";
import OpenSpotlightButton from "@features/clinician-dashboard/components/OpenSpotlightButton";
import { humanizeTime } from "@features/clinician-dashboard/utils";
import { ArrowRightAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import NotificationListItemAvatar from "./NotificationListItemAvatar";

const getLink = (notification: Notification) => {
  switch (notification.type) {
    case NotificationType.MEMBER_COMPLETED_JOURNAL_ENTRY:
      return `/members/${notification.member_user_id}?tab=${encodeURIComponent(
        MemberChartPageTabs.JOURNAL_ENTRIES
      )}`;

    case NotificationType.MEMBER_COMPLETED_ASSESSMENT:
      return `/members/${notification.member_user_id}?tab=${encodeURIComponent(
        MemberChartPageTabs.HISTORICAL_ASSESSMENT_RESULTS
      )}`;

    case NotificationType.MEMBER_COMPLETED_ROI:
      return `/members/${notification.member_user_id}?tab=${encodeURIComponent(
        MemberChartPageTabs.RELEASE_OF_INFORMATION
      )}`;

    case NotificationType.MEMBER_COMPLETED_CONSENT_FORM:
      return `/members/${notification.member_user_id}?tab=${encodeURIComponent(
        MemberChartPageTabs.INFORMED_CONSENT
      )}`;

    case NotificationType.MEMBER_COMPLETED_NPP_FORM:
      return `/members/${notification.member_user_id}?tab=${encodeURIComponent(
        MemberChartPageTabs.INFORMED_CONSENT
      )}`;

    case NotificationType.MEMBER_COMPLETED_REVIEW:
      return "/progress";

    case NotificationType.MEMBER_COMPLETED_EXPOSURE_PRACTICE:
    case NotificationType.MEMBER_LEFT_EXPOSURE_PRACTICE_NOTE:
    case NotificationType.MEMBER_USED_SOS:
      return `/members/${notification.member_user_id}?tab=${encodeURIComponent(
        MemberChartPageTabs.SOS_EXERCISES
      )}`;

    case NotificationType.APPOINTMENT_SCHEDULED:
    case NotificationType.APPOINTMENT_RESCHEDULED:
    case NotificationType.APPOINTMENT_CANCELLED:
      return `/members/${notification.member_user_id}?tab=${MemberChartPageTabs.SESSIONS}`;

    case NotificationType.LATE_RESPONSE_MESSAGE_REMINDER:
    case NotificationType.BIRTHDAY_MESSAGE_REMINDER:
      return `/messaging?redirectId=${
        (notification?.payload as { chat_channel_id: number })?.chat_channel_id
      }`;
    case NotificationType.MEMBER_REMINDER:
      return `/members/${notification.member_user_id}?tab=${MemberChartPageTabs.MEMBER_REMINDER}`;
    case NotificationType.POST_FIRST_SESSION_MESSAGE_REMINDER:
      return `/messaging?redirectId=${
        (notification?.payload as { chat_channel_id: number })?.chat_channel_id
      }`;
    case NotificationType.TREATMENT_MILESTONE_LETTER_REMINDER:
    case NotificationType.MEMBER_MILESTONE_FIVE_DAY_WARNING:
    case NotificationType.MEMBER_SNOOZED_MILESTONE_WEEKLY_REMINDER:
    case NotificationType.MEMBER_AUTO_MILESTONE_LETTER_SENT:
      return `/members/${notification.member_user_id}?tab=${encodeURIComponent(
        MemberChartPageTabs.HISTORICAL_ASSESSMENT_RESULTS
      )}`;
    case NotificationType.AI_NEW_MEMBER_EVENT:
      return `/messaging?redirectId=${
        (notification?.payload as { chat_channel_id: number })?.chat_channel_id
      }`;
    case NotificationType.MEMBER_SET_PERSONAL_GOAL:
      return `/members/${notification.member_user_id}?tab=${MemberChartPageTabs.MEMBER_GOALS}`;
    default:
      return undefined;
  }
};

const Loading = () => <Skeleton width="100%" height="72px" />;

function NotificationListItem({
  notification,
  rounded = true,
}: {
  notification: Notification;
  rounded?: boolean;
}) {
  return (
    <ListItem
      sx={{
        borderRadius: rounded ? "8px" : undefined,
        "&:hover": {
          backgroundColor: "#F1F5F9",
        },
        ".notification-list-item-view-button": {
          display: "none",
        },
        "&:hover .notification-list-item-timestamp": {
          display: "none",
        },
        "&:hover .notification-list-item-view-button": {
          display: "flex",
        },
        "&:focus-within .notification-list-item-timestamp": {
          display: "none",
        },
        "&:focus-within .notification-list-item-view-button": {
          display: "flex",
        },
        display: "flex",
      }}
      key={notification.id}
    >
      <NotificationListItemAvatar notification={notification} />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
      >
        <ListItemText
          sx={{
            flex: 1,
          }}
          primaryTypographyProps={{
            sx: {
              fontWeight: 700,
            },
          }}
          primary={
            <OpenSpotlightButton
              userId={notification.member_user_id}
              name={notification.member_name}
              is_birthday={notification.is_birthday}
            />
          }
          secondary={notification.title}
        />

        <Typography
          variant="body2"
          className="notification-list-item-timestamp"
        >
          {humanizeTime(notification.created_at)}
        </Typography>

        <Box
          className="notification-list-item-view-button"
          sx={{
            ".notification-list-item-view-button-expanded": {
              display: "none",
            },
            ".notification-list-item-view-button-collapsed": {
              display: "flex",
            },
            "&:hover .notification-list-item-view-button-expanded": {
              display: "flex",
            },
            "&:hover .notification-list-item-view-button-collapsed": {
              display: "none",
            },
          }}
        >
          <IconButton
            sx={{
              color: "#0F172A",
              backgroundColor: "#FFF",
            }}
            onClick={() => {
              window.open(getLink(notification), "_blank");
            }}
            className="notification-list-item-view-button-collapsed"
          >
            <ArrowRightAlt />
          </IconButton>

          <Button
            className="notification-list-item-view-button-expanded"
            onClick={() => {
              window.open(getLink(notification), "_blank");
            }}
            sx={{
              backgroundColor: "#E2E8F0",
              color: "#0F172A",
              padding: 1,
              lineHeight: 1,
              "&:hover": {
                backgroundColor: "#E2E8F0",
              },
              borderRadius: 999,
            }}
            endIcon={<ArrowRightAlt />}
          >
            View
          </Button>
        </Box>
      </Stack>
    </ListItem>
  );
}

NotificationListItem.Loading = Loading;

export default NotificationListItem;
