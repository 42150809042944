import React from "react";

interface Actions {
  handleSessionRecap(message: string): unknown;
  handleSuggestions: () => void;
  handleMessage: (message: string) => void;
}

interface MessageParserProps {
  children: React.ReactNode;
  actions: Actions;
}

const MessageParser: React.FC<MessageParserProps> = ({ children, actions }) => {
  const parse = (message: string) => {
    if (message.includes("suggestions")) {
      actions.handleSuggestions();
    } else if (message === "sessionRecap") {
      actions.handleSessionRecap(message);
    } else if (message) {
      actions.handleMessage(message);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement<any>, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
