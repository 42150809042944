import {
  Notification,
  NotificationType,
} from "@core/services/nocd-api/notifications/queries/useMyNotifications";
import NotificationIcon from "@mui/icons-material/Notifications";
import { Avatar, Badge, Box, ListItemAvatar } from "@mui/material";
import { useMemo } from "react";

import CalendarDays from "../icons/CalendarDays";
import DocumentText from "../icons/DocumentText";
import Envelope from "../icons/Envelope";
import Flag from "../icons/Flag";
import Pencil from "../icons/Pencil";
import Rocket from "../icons/Rocket";
import Star from "../icons/Star";

const BADGE_COLORS = {
  green: {
    color: "nocdGreen.main",
    bgcolor: "#F0FDF4",
  },
  red: {
    color: "nocdRed.main",
    bgcolor: "#FFF5F3",
  },
  orange: {
    color: "nocdOrange.main",
    bgcolor: "#FFF8EC",
  },
  blue: {
    color: "nocdBlue.main",
    bgcolor: "#EDF5FE",
  },
  purple: {
    color: "#E3E4FC",
    bgcolor: "#F9F5FF",
  },
} as const;

const getNotificationTypeBadge = (type: NotificationType) => {
  switch (type) {
    case NotificationType.APPOINTMENT_SCHEDULED:
    case NotificationType.APPOINTMENT_RESCHEDULED:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.green,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <CalendarDays width={12} height={12} />
        </Box>
      );
    case NotificationType.APPOINTMENT_CANCELLED:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.red,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <CalendarDays width={12} height={12} />
        </Box>
      );
    case NotificationType.MEMBER_COMPLETED_ASSESSMENT:
    case NotificationType.MEMBER_COMPLETED_ROI:
    case NotificationType.MEMBER_COMPLETED_CONSENT_FORM:
    case NotificationType.MEMBER_COMPLETED_NPP_FORM:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.green,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <DocumentText width={12} height={12} />
        </Box>
      );
    case NotificationType.MEMBER_COMPLETED_REVIEW:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.orange,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <Star width={12} height={12} />
        </Box>
      );

    case NotificationType.MEMBER_COMPLETED_EXPOSURE_PRACTICE:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.green,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <Flag width={12} height={12} />
        </Box>
      );

    case NotificationType.MEMBER_COMPLETED_JOURNAL_ENTRY:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.green,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <Pencil width={12} height={12} />
        </Box>
      );

    case NotificationType.MEMBER_LEFT_EXPOSURE_PRACTICE_NOTE:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.blue,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <Pencil width={12} height={12} />
        </Box>
      );

    case NotificationType.BIRTHDAY_MESSAGE_REMINDER:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.orange,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <Star width={12} height={12} />
        </Box>
      );
    case NotificationType.MEMBER_REMINDER:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.orange,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <NotificationIcon
            sx={{
              width: 12,
              height: 12,
            }}
          />
        </Box>
      );
    case NotificationType.MEMBER_MILESTONE_FIVE_DAY_WARNING:
    case NotificationType.MEMBER_SNOOZED_MILESTONE_WEEKLY_REMINDER:
    case NotificationType.MEMBER_AUTO_MILESTONE_LETTER_SENT:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.orange,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <Envelope width={12} height={12} />
        </Box>
      );
    case NotificationType.MEMBER_SET_PERSONAL_GOAL:
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...BADGE_COLORS.purple,
            borderRadius: 999,
            p: 0.5,
          }}
        >
          <Rocket width={12} height={12} />
        </Box>
      );
    default:
      return null;
  }
};

export default function NotificationListItemAvatar({
  notification,
}: {
  notification: Notification;
}) {
  const badgeContent = useMemo(
    () => getNotificationTypeBadge(notification.type),
    [notification.type]
  );

  return (
    <ListItemAvatar>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={badgeContent}
      >
        <Avatar
          sx={{
            width: 36,
            height: 36,
          }}
          src={notification.member_avatar_url}
          alt={notification.member_name}
        />
      </Badge>
    </ListItemAvatar>
  );
}
