import { SVGProps } from "react";

const Rocket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 34 34"
    width="12"
    height="12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9968 13.3168C17.7921 9.72465 22.1597 7.41174 27.066 7.41174C27.2591 7.41174 27.4443 7.48844 27.5808 7.62495C27.7173 7.76147 27.794 7.94662 27.794 8.13968C27.794 13.047 25.4811 17.4137 21.8889 20.2099C22.0383 21.1458 21.9829 22.1029 21.7264 23.0153C21.47 23.9277 21.0187 24.7736 20.4036 25.4946C19.7885 26.2156 19.0243 26.7946 18.1637 27.1916C17.3032 27.5886 16.3667 27.7941 15.419 27.7941C15.2259 27.7941 15.0408 27.7174 14.9042 27.5809C14.7677 27.4444 14.691 27.2592 14.691 27.0662V23.0567C13.7475 22.3116 12.8947 21.4585 12.15 20.5147H8.13956C7.9465 20.5147 7.76135 20.438 7.62483 20.3015C7.48831 20.165 7.41162 19.9798 7.41162 19.7867C7.41154 18.8389 7.61713 17.9024 8.01418 17.0417C8.41124 16.1811 8.99031 15.4168 9.71144 14.8017C10.4326 14.1866 11.2786 13.7353 12.1911 13.4789C13.1036 13.2226 14.0608 13.1673 14.9968 13.3168ZM20.5146 12.5073C19.9354 12.5073 19.3799 12.7374 18.9704 13.147C18.5608 13.5565 18.3307 14.112 18.3307 14.6912C18.3307 15.2703 18.5608 15.8258 18.9704 16.2354C19.3799 16.6449 19.9354 16.875 20.5146 16.875C21.0937 16.875 21.6492 16.6449 22.0588 16.2354C22.4683 15.8258 22.6984 15.2703 22.6984 14.6912C22.6984 14.112 22.4683 13.5565 22.0588 13.147C21.6492 12.7374 21.0937 12.5073 20.5146 12.5073Z"
      fill="#545CEA"
    />
    <path
      d="M11.0609 22.6908C11.1376 22.6337 11.2022 22.562 11.2512 22.4798C11.3001 22.3976 11.3324 22.3067 11.3462 22.212C11.36 22.1174 11.355 22.021 11.3315 21.9283C11.3081 21.8356 11.2666 21.7484 11.2094 21.6717C11.1522 21.595 11.0805 21.5304 10.9984 21.4814C10.9162 21.4325 10.8252 21.4002 10.7306 21.3864C10.636 21.3726 10.5395 21.3776 10.4468 21.4011C10.3541 21.4245 10.2669 21.466 10.1903 21.5232C9.44572 22.077 8.86679 22.8239 8.51609 23.6831C8.16539 24.5422 8.05628 25.4808 8.20057 26.3975C8.22403 26.5502 8.29545 26.6914 8.4045 26.8008C8.51356 26.9102 8.65458 26.9821 8.80718 27.0061C9.72393 27.1502 10.6627 27.041 11.5218 26.6901C12.3809 26.3392 13.1278 25.7601 13.6815 25.0154C13.7405 24.9389 13.7837 24.8515 13.8086 24.7582C13.8334 24.6648 13.8395 24.5675 13.8264 24.4718C13.8133 24.3761 13.7812 24.284 13.7322 24.2008C13.6831 24.1176 13.618 24.045 13.5406 23.9873C13.4631 23.9295 13.375 23.8877 13.2813 23.8643C13.1876 23.841 13.0902 23.8365 12.9947 23.8511C12.8992 23.8658 12.8076 23.8993 12.7253 23.9497C12.6429 24.0001 12.5713 24.0664 12.5148 24.1448C12.1764 24.6 11.736 24.9696 11.229 25.2241C10.7221 25.4786 10.1626 25.6109 9.5953 25.6104C9.5953 24.4165 10.1699 23.3557 11.0609 22.6908Z"
      fill="#545CEA"
    />
  </svg>
);

export default Rocket;
