import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import { MemberFinderResponse } from "../types";

interface CustomQueryOptions
  extends UseQueryOptions<MemberFinderResponse, Error> {
  durationInMins: number;
  skipMaxFirstSessionCheck: boolean;
  includeSlotsWithNoCandidates?: boolean;
  skipMoveUpsAndWaitlists?: boolean;
  skipMembersWithNextSession?: boolean;
}

const useMemberFinder = (
  clinicianEmail: string,
  options?: CustomQueryOptions
): UseQueryResult<MemberFinderResponse, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "member-finder",
      clinicianEmail,
      options?.durationInMins,
      options?.skipMaxFirstSessionCheck,
      options?.includeSlotsWithNoCandidates,
      options?.skipMoveUpsAndWaitlists,
      options?.skipMembersWithNextSession,
    ],
    ({ signal }) =>
      api
        .get<MemberFinderResponse>(
          `/v1/scheduling/clinicians/${clinicianEmail}/member_finder`,
          {
            params: {
              duration_in_mins: options?.durationInMins ?? 60,
              skip_max_first_session_check: options?.skipMaxFirstSessionCheck
                ? 1
                : 0,
              include_slots_with_no_candidates:
                options?.includeSlotsWithNoCandidates ? 1 : 0,
              skip_move_ups_and_waitlists: options?.skipMoveUpsAndWaitlists
                ? 1
                : 0,
              skip_members_with_next_session:
                options?.skipMembersWithNextSession ? 1 : 0,
              days_to_look_out: 21,
            },
            signal,
            headers: {
              Authorization: accessToken,
            },
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled:
        !!accessToken && !!clinicianEmail && options?.durationInMins != null,
      ...(options ?? {}),
      retry: false,
      // This should stay last, so the caller can't override it.
      // This is an expensive query, so it should never be auto-refetched.
      staleTime: Infinity,
    }
  );
};

export default useMemberFinder;
