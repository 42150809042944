import { EhrRoles } from "@core/constants";
import { MemberContacts } from "@core/services/nocd-api/members";
import { SessionTypes, SessionTypesV2, SessionTypesV3 } from "@core/types";
import { Goal } from "@features/appointment-treatment-plans/types";
import { DischargeRequestStatus } from "@features/discharge-requests/types";
import { ChannelWhisper } from "@features/messaging/hooks/useChatChannelWhispers";

import {
  SupervisorAppointmentNoteReviewStatus,
  SupervisorReviewNote,
} from "./appointments";

export interface APIError {
  error_short_name: string;
  message: string;
}

export enum ProtocolTypes {
  ADULT = "Adult",
  ADOLESCENT = "Adolescent",
  CHILD = "Child",
}

interface MemberInsuranceInfo {
  using_insurance: boolean;
  insurance_provider: string | null;
  plan_manager: string | null;
  has_active_coverage: boolean;
}

export interface Member {
  accepted_ocicvr_consent_id?: number;
  active_subscription?: number;
  at_or_above_linked_roi_age?: boolean;
  address?: string;
  billing_paused_states?: string;
  can_schedule_session: boolean;
  can_reschedule_session: boolean;
  can_cancel_session: boolean;
  can_transfer_therapist: boolean;
  care_team_flag?: number;
  care_team_flag_reason?: string;
  city?: string;
  clinical_scratchpad?: string;
  clinician_email?: string;
  clinician_timezone?: string;
  contact_notes?: MemberContactNote[];
  country_code?: string;
  current_scheduling_frequency?: string;
  diagnostic_timezone?: string;
  discharge_notes?: MemberDischargeNote[];
  dob?: string | Date;
  email?: string;
  emergency_name?: string;
  emergency_phone?: string;
  emergency_relation?: string;
  emergency_email?: string;
  first_name?: string;
  guid?: string;
  has_first_session?: boolean;
  has_second_session?: boolean;
  is_linked_account?: 1 | 0 | null;
  is_linked_roi_approved?: number;
  is_referred_to_hloc_therapy?: number;
  is_root_account?: 1 | 0 | null;
  is_self_scheduling_enabled?: number;
  is_test_user?: number;
  insurance_info?: MemberInsuranceInfo;
  last_name?: string;
  linked_roi_last_completed?: string | Date;
  linked_roi_expiration_date?: string | Date;
  linked_roi_patient_name?: string;
  linked_roi_root_name?: string;
  linked_roi_root_relation?: string;
  linked_roi_signed_by?: string;
  link_id?: number;
  notice_of_privacy_practices?: MemberNoticeOfPrivacyPractices;
  nocd411_consent?: MemberNOCD411Consent;
  notify_pref_method_email?: number;
  notify_pref_freq_48hr?: number;
  notify_pref_freq_24hr?: number;
  notify_pref_freq_1hr?: number;
  notes?: string;
  peer_advisor_email?: string;
  phone?: string;
  preferred_name?: string;
  pronouns?: string;
  protocol_type?: string;
  root_account_data?: {
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
  };
  rp_name?: string;
  rp_relation?: string;
  rp_phone?: string;
  rp_email?: string;
  reminder_emails?: ReminderEmailValues[];
  safety_plan?: SafetyPlan;
  second_address?: string;
  second_address_city?: string;
  second_address_zipcode?: string;
  state?: string;
  summary_private_notes_global?: string;
  therapy_checkin_count_start?: number;
  therapy_consent?: MemberTherapyConsent;
  therapy_session_count_start?: number;
  trauma_interview_forms?: MemberTraumaInterviewForm[];
  userID?: number;
  zipcode?: string;
  parents_living_status?: string | null;
  primary_outcome_form?: string | null;
  primary_outcome_form_subscore_name?: string | null;
  abn_medicare_form?: MemberABNMedicare;
  using_medicaid_or_medicare?: number | null;
  gov_healthcare_program?: string | null;
  member_contacts?: MemberContacts[];
  has_shared_custody?: string | null;
  country_other?: string | null;
  notify_about_earlier_sessions?: boolean | null;
  clinical_reviewed_scheduling_frequency?: string | null;
  clinical_reviewed_scheduling_frequency_updated_at?: string | null;
  clinical_reviewed_scheduling_frequency_updated_by?: string | null;
  bulk_scheduling_guidance?: string | null;
  member_adherence_expectation?: string | null;
  member_adherence_expectation_details?: string | null;
  compatibility_rate_with_member?: string | null;
  compatibility_rate_with_member_details?: string | null;
  assigned_to_protocol_compliance?: {
    not_compliant_reason: string;
    not_compliant_reason_details: string;
    updated_at?: string;
    updated_by?: string;
  };
  conqueror_status?: string | null;
  member_has_next_session?: boolean;
  member_next_session_date?: string | null;
  member_num_next_sessions?: number | null;
  member_next_session_type?: string | null;
  next_session_clinician_name?: string | null;
  member_num_of_completed_sessions?: number | null;

  high_risk_assessment?: {
    hospitalized_recently?: string | null;
    followup_care_recently?: string | null;
    intent_self_harm_recently?: string | null;
    current_intent_self_harm?: string | null;
    hospital_discharge_date?: string | null;
  } | null;

  adherence?: {
    current_week?: AdherenceWindow;
    second_week?: AdherenceWindow;
    third_week?: AdherenceWindow;
    fourth_week?: AdherenceWindow;
    current_month?: AdherenceWindow;
    second_month?: AdherenceWindow;
    other_window?: AdherenceWindow;
  };
  sched_protocol_compliant?: 1 | 0 | null;
  risk_stratification_flag?: {
    risk_level: string;
  };
  need_sign_support_plan_attestation?: boolean;
  clinician_first_name?: string;
  all_time_conqueror_status?: boolean;
}

interface AdherenceWindow {
  sessions_expected: number;
  sessions_scheduled: number;
  sessions_needed: number;
  meets_adherence: boolean;
}

interface AssignedMember {
  active_subscription: number;
  consent_name: string | null;
  current_scheduling_frequency?: string | null;
  diagnostic_timezone?: string | null;
  first_name: string | null;
  email?: string | null;
  id?: number;
  is_linked_account?: 1 | 0 | null;
  is_root_account?: 1 | 0 | null;
  is_test_user: number;
  last_name: string | null;
  last_session: string | null;
  last_scheduling_note_text?: string | null;
  preferred_name: string | null;
  protocol_type: string | null;
  sessions_scheduled?: number | null;
  sessions_completed?: number | null;
  nearest_member_portal_forms_due_at: string | null;
  nearest_member_portal_form_names?: string | null;
  next_session_time?: string | null;
  percentage_change_in_diagnosis?: string | null;
  primary_diagnosis_form?: string | null;
  userid: number;
  sched_protocol_compliant?: 1 | 0 | null;
  conquerer?: string | null;

  adherence?: {
    current_week?: AdherenceWindow;
    second_week?: AdherenceWindow;
    third_week?: AdherenceWindow;
    fourth_week?: AdherenceWindow;
    current_month?: AdherenceWindow;
    second_month?: AdherenceWindow;
    other_window?: AdherenceWindow;
  };
  is_temp_unavailable?: boolean;
  unavailable_to?: string | null;
  billing_status: string | null;
}

export type AssignedMembers = AssignedMember[];

export interface SafetyPlan {
  id?: number;
  user_id?: number;
  warning_signs?: string;
  coping_strategies?: string;
  social_distractions?: string;
  people_support?: string;
  crisis_help?: string;
  safe_environment?: string;
  created_at?: string;
  emergency_contact_verified?: number;
  people_support_json?:
    | {
        name: string;
        phone: string;
      }[]
    | null;
  triggers_and_stressors?: string | null;
  professionals_agencies_support_json?:
    | {
        name: string;
        phone: string;
      }[]
    | null;
  discussed_safe_environment?: number;
  worth_living_for?: string | null;
  is_high_or_moderate_risk_stratification?: boolean;
  country_code?: string | null;
}
export interface MemberAppointmentBGDiagnosticsForm {
  bg_psychiatric_history?: string;
  bg_satisfied_with_med_provider?: string;
  bg_verbal_consent_given?: string;
  bg_verbal_consent_given_by?: string;
  bg_brain_trauma?: number;
  bg_brain_trauma_text?: string;
  bg_consent_reviewed?: number;
  bg_linked_account_roi_reviewed?: number;
  bg_coping_skills?: string;
  bg_current_relationship_status?: string;
  bg_current_relationship_status_text?: string;
  bg_demographic?: string;
  bg_family_history_json?: {
    family_history: {
      ocd: string[];
      anxiety: string[];
      depression_mood: string[];
      substance_abuse: string[];
      schizophrenia_psychosis: string[];
    };
  };
  bg_legal_problems?: string;
  bg_legal_problems_text?: string;
  bg_medical_history?: string;
  bg_medical_history_text?: string;
  bg_medical_past_er_visits_text?: string;
  bg_medication_status?: string;
  bg_medication_status_text?: string;
  bg_misdiagnosed_conditions_json?: {
    conditions: string[];
  };
  bg_misdiagnosed_conditions_other?: string;
  bg_misdiagnosed_ocd?: string;
  bg_ocd_criteria_time?: string;
  bg_ocd_criteria_units?: string;
  bg_outpatient_treatment?: string;
  bg_outpatient_treatment_text?: string;
  bg_mental_health_hospitalization_yes_no?: string;
  bg_mental_health_hospitalization_text?: string;
  bg_patient_goals?: string;
  bg_presenting_problem?: string;
  bg_prior_non_ocd_diagnosis?: string;
  bg_prior_non_ocd_diagnosis_text?: string;
  bg_prior_ocd_by_prof?: string;
  bg_trauma_denied?: number | string;
  bg_trauma_items?: {
    trauma_items: string[];
  };
  bg_trauma_other?: string;
  bg_verified_identity?: number;
  created_at?: string;
  id?: number;
  member_appointments_id?: number;
  ocd_tab_diamond_all_screenings_negative?: number;
  ocd_tab_diamond_details?: string;
  ocd_tab_dsm_distress?: number;
  ocd_tab_dsm_distress_domains?: string[];
  ocd_tab_dsm_functional_impairment?: number;
  ocd_tab_dsm_insight?: string;
  ocd_tab_dsm_part_a?: number;
  ocd_tab_dsm_part_b?: number;
  ocd_tab_dsm_part_c?: number;
  ocd_tab_dsm_part_d?: number;
  ocd_tab_dsm_tic_related?: string;
  ocd_tab_subtype?: string;
  ocd_tab_subtype_contamination?: string;
  ocd_tab_subtype_contamination_text?: string;
  ocd_tab_subtype_harm?: string;
  ocd_tab_subtype_harm_text?: string;
  ocd_tab_subtype_other?: string;
  ocd_tab_subtype_other_text?: string;
  ocd_tab_subtype_religious?: string;
  ocd_tab_subtype_religious_text?: string;
  ocd_tab_subtype_sexual?: string;
  ocd_tab_subtype_sexual_text?: string;
  ocd_tab_subtype_somatic?: string;
  ocd_tab_subtype_somatic_text?: string;
  ocd_tab_subtype_symmetry?: string;
  ocd_tab_subtype_symmetry_text?: string;
  updated_at?: string;
  user_id?: number;
  ocd_assessment_details?: string;
  bg_additional_healthcare_providers?: string;
  bg_socioeconomic_status?: string;
  bg_preferred_language?: string;
  bg_preferred_language_other?: string;
  bg_ethnicity?: string[];
  bg_ethnicity_other?: string;
  bg_religious_background?: string;
  bg_religious_background_other?: string;
  bg_employment_status?: string;
  bg_employment_status_other?: string;
  bg_student_status?: string;
  bg_student_status_other?: string;
  bg_gender_identity?: string;
  bg_gender_identity_other?: string;
  bg_sexual_orientation?: string;
  bg_sexual_orientation_other?: string;
  bg_military_served?: string;
  bg_military_served_details?: string;
  bg_special_therapy_services?: string;
  bg_special_therapy_services_details?: string;
  bg_prior_ocd_by_prof_details?: string;
  bg_living_situation?: string;
  bg_living_situation_details?: string;
  bg_children_status?: string;
  bg_children_status_details?: string;
  bg_primary_support_people?: string;
  bg_quality_of_work_relationships?: string;
  bg_quality_of_family_relationships?: string;
  bg_quality_of_social_relationships?: string;
  bg_share_progress_with_other_providers?: string;
  bg_share_progress_with_other_providers_text?: string;
  bg_years_of_education?: string;
  bg_additional_healthcare_providers_json?: Record<string, string>[];
  bg_has_additional_healthcare_providers?: string;
  bg_family_history_details?: string;
  bg_meet_ocd_criteria?: string;
  completed?: number;
  bg_primary_support_people_yes_no?: string;
  bg_current_stressors?: string;
  bg_current_stressors_text?: string;
  bg_pertinent_info?: string;
  version?: number;
  bg_close_family_relationships?: string;
  bg_psychiatric_history_reviewed?: number;
  bg_bio_history_reviewed?: number;
  bg_life_goals_post_treatment?: string;
}

export interface MemberAppointmentDiamondAdultForm {
  completed: number | null;
  created_at?: string;
  id?: number;
  member_appointments_id?: number;
  protocol_type?: string;
  q1_adult_frequent_thoughts: number;
  q2_adult_repetitive_behaviors: number;
  q3_adult_worry_appearance: number;
  q4_adult_excessively_cluttered: number;
  q5_adult_hair_pulling: number;
  q6_adult_skin_picking: number;
  q7_adult_socially_anxious: number;
  q8_adult_panic_attack: number;
  q9_adult_fearful_of_escape: number;
  q10_adult_worry_about_many_things: number;
  q11_adult_afraid_of_objects_situations_activities: number;
  q12_adult_afraid_to_be_away_from_a_person: number;
  q13_adult_problematically_happy: number;
  q14_adult_depressed_2_years: number;
  q15_adult_unmotivated_2_weeks: number;
  q16_adult_women_only_really_depressed_during_period: number;
  q17_adult_event_trauma: number;
  q18_adult_hard_time_with_stressful_experience: number;
  q19_adult_strange_beliefs: number;
  q20_adult_sensory_experience: number;
  q21_adult_avoid_eating_due_to_weight: number;
  q22_adult_eating_binges: number;
  q23_adult_eat_very_little: number;
  q24_adult_physical_health: number;
  q25_adult_worry_of_serious_medical_illness: number;
  q26_adult_alcohol: number;
  q27_adult_drug_abuse: number;
  q28_adult_difficulty_paying_attn: number;
  q29_adult_difficult_sitting_still: number;
  q30_adult_spastic_movements: number;

  q1_adult_frequent_thoughts_positive: number;
  q2_adult_repetitive_behaviors_positive: number;
  q3_adult_worry_appearance_positive: number;
  q4_adult_excessively_cluttered_positive: number;
  q5_adult_hair_pulling_positive: number;
  q6_adult_skin_picking_positive: number;
  q7_adult_socially_anxious_positive: number;
  q8_adult_panic_attack_positive: number;
  q9_adult_fearful_of_escape_positive: number;
  q10_adult_worry_about_many_things_positive: number;
  q11_adult_afraid_of_objects_situations_activities_positive: number;
  q12_adult_afraid_to_be_away_from_a_person_positive: number;
  q13_adult_problematically_happy_positive: number;
  q14_adult_depressed_2_years_positive: number;
  q15_adult_unmotivated_2_weeks_positive: number;
  q16_adult_women_only_really_depressed_during_period_positive: number;
  q17_adult_event_trauma_positive: number;
  q18_adult_hard_time_with_stressful_experience_positive: number;
  q19_adult_strange_beliefs_positive: number;
  q20_adult_sensory_experience_positive: number;
  q21_adult_avoid_eating_due_to_weight_positive: number;
  q22_adult_eating_binges_positive: number;
  q23_adult_eat_very_little_positive: number;
  q24_adult_physical_health_positive: number;
  q25_adult_worry_of_serious_medical_illness_positive: number;
  q26_adult_alcohol_positive: number;
  q27_adult_drug_abuse_positive: number;
  q28_adult_difficulty_paying_attn_positive: number;
  q29_adult_difficult_sitting_still_positive: number;
  q30_adult_spastic_movements_positive: number;

  q1_adult_frequent_thoughts_details: string;
  q2_adult_repetitive_behaviors_details: string;
  q3_adult_worry_appearance_details: string;
  q4_adult_excessively_cluttered_details: string;
  q5_adult_hair_pulling_details: string;
  q6_adult_skin_picking_details: string;
  q7_adult_socially_anxious_details: string;
  q8_adult_panic_attack_details: string;
  q9_adult_fearful_of_escape_details: string;
  q10_adult_worry_about_many_things_details: string;
  q11_adult_afraid_of_objects_situations_activities_details: string;
  q12_adult_afraid_to_be_away_from_a_person_details: string;
  q13_adult_problematically_happy_details: string;
  q14_adult_depressed_2_years_details: string;
  q15_adult_unmotivated_2_weeks_details: string;
  q16_adult_women_only_really_depressed_during_period_details: string;
  q17_adult_event_trauma_details: string;
  q18_adult_hard_time_with_stressful_experience_details: string;
  q19_adult_strange_beliefs_details: string;
  q20_adult_sensory_experience_details: string;
  q21_adult_avoid_eating_due_to_weight_details: string;
  q22_adult_eating_binges_details: string;
  q23_adult_eat_very_little_details: string;
  q24_adult_physical_health_details: string;
  q25_adult_worry_of_serious_medical_illness_details: string;
  q26_adult_alcohol_details: string;
  q27_adult_drug_abuse_details: string;
  q28_adult_difficulty_paying_attn_details: string;
  q29_adult_difficult_sitting_still_details: string;
  q30_adult_spastic_movements_details: string;
  session_type?: string;
  updated_at?: string;
  user_id?: number;
  // v2 questions
  q31_adult_frequent_thoughts_details_text?: string;
  q32_adult_frequent_thoughts_come_up?: number;
  q33_adult_frequent_thoughts_bother?: number;
  q34_adult_frequent_thoughts_ignore?: number;

  q35_adult_repetitive_behaviors_stop_doing?: number;
  q36_adult_repetitive_behaviors_afraid?: string;

  q37_adult_alcohol_anyone_said?: number;
  q38_adult_alcohol_caused_problems?: number;

  q39_adult_drug_abuse_anyone_said?: number;
  q40_adult_drug_abuse_caused_problems?: number;

  q41_adult_skin_picking_scars?: number;

  q42_adult_spastic_movements_some_body_part?: number;
  q43_adult_spastic_movements_multiple_body_parts?: number;
  q44_adult_spastic_movements_making_sounds?: number;

  q45_adult_excessively_cluttered_others_throw_away?: number;
  q46_adult_excessively_cluttered_important_to_save?: number;

  q47_adult_unmotivated_lost_interest?: number;

  q48_adult_felt_overly_confident?: number;
  q49_adult_felt_extremely_irritable?: number;

  q50_adult_avoid_eating_other_things?: number;

  q51_adult_eat_very_little_eating_habits?: number;

  q52_adult_socially_anxious_when_people_observe?: number;

  q53_adult_panic_attack_sudden_rush?: number;
  q54_adult_panic_attack_come_without_reason?: number;

  q55_adult_worry_excessive?: number;
  q56_adult_worry_anxiety_cause_concerns?: number;

  q57_adult_physical_health_interfered?: number;
  q58_adult_physical_health_excessive_worry?: number;

  q59_adult_worry_appearance_spend_time_doing_things?: number;

  q60_adult_hair_pulling_visible_hair_loss?: number;

  q61_adult_worry_of_serious_medical_illness_get_alarmed?: number;

  q62_adult_event_trauma_experience_incidents?: number;
  q63_adult_depressed_2_years_occur_on_more_days?: number;
  q64_adult_eating_binges_behaviors_prevent_weight_gain?: number;
  q67_adult_eating_binges_frequency?: number;

  q65_adult_frequent_thoughts_description?: string[];
  q65_adult_frequent_thoughts_description_other?: string;

  q66_adult_repetitive_behaviors_description?: string[];
  q66_adult_repetitive_behaviors_description_other?: string;

  // v3 questions for diagnostic_assessment
  q1_adult_frequent_thoughts_diagnostic_assessment?: string;
  q2_adult_repetitive_behaviors_diagnostic_assessment?: string;
  q3_adult_worry_appearance_diagnostic_assessment?: string;
  q4_adult_excessively_cluttered_diagnostic_assessment?: string;
  q5_adult_hair_pulling_diagnostic_assessment?: string;
  q6_adult_skin_picking_diagnostic_assessment?: string;
  q7_adult_socially_anxious_diagnostic_assessment?: string;
  q8_adult_panic_attack_diagnostic_assessment?: string;
  q9_adult_fearful_of_escape_diagnostic_assessment?: string;
  q10_adult_worry_about_many_things_diagnostic_assessment?: string;
  q11_adult_afraid_of_objects_diagnostic_assessment?: string;
  q13_adult_problematically_happy_diagnostic_assessment?: string;
  q14_adult_depressed_2_years_diagnostic_assessment?: string;
  q15_adult_unmotivated_2_weeks_diagnostic_assessment?: string;
  q17_adult_event_trauma_diagnostic_assessment?: string;
  q19_adult_strange_beliefs_diagnostic_assessment?: string;
  q20_adult_sensory_experience_diagnostic_assessment?: string;
  q21_adult_avoid_eating_due_to_weight_diagnostic_assessment?: string;
  q22_adult_eating_binges_diagnostic_assessment?: string;
  q23_adult_eat_very_little_diagnostic_assessment?: string;
  q24_adult_physical_health_diagnostic_assessment?: string;
  q25_adult_worry_of_serious_medical_illness_diagnostic_assessment?: string;
  q26_adult_alcohol_diagnostic_assessment?: string;
  q27_adult_drug_abuse_diagnostic_assessment?: string;
  q30_adult_spastic_movements_diagnostic_assessment?: string;
  adult_proxy_balance_of_will_assess?: string;
  member_answered?: {
    q1_adult_frequent_thoughts?: boolean;
    q31_adult_frequent_thoughts_details_text?: boolean;
    q32_adult_frequent_thoughts_come_up: boolean;
    q33_adult_frequent_thoughts_bother: boolean;
    q34_adult_frequent_thoughts_ignore: boolean;

    q2_adult_repetitive_behaviors: boolean;
    q35_adult_repetitive_behaviors_stop_doing: boolean;
    q36_adult_repetitive_behaviors_afraid: boolean;

    q26_adult_alcohol: boolean;
    q37_adult_alcohol_anyone_said: boolean;
    q38_adult_alcohol_caused_problems: boolean;

    q27_adult_drug_abuse: boolean;
    q39_adult_drug_abuse_anyone_said: boolean;
    q40_adult_drug_abuse_caused_problems: boolean;

    q6_adult_skin_picking: boolean;
    q41_adult_skin_picking_scars: boolean;

    q30_adult_spastic_movements: boolean;
    q42_adult_spastic_movements_some_body_part: boolean;
    q43_adult_spastic_movements_multiple_body_parts: boolean;
    q44_adult_spastic_movements_making_sounds: boolean;

    q4_adult_excessively_cluttered: boolean;
    q45_adult_excessively_cluttered_others_throw_away: boolean;
    q46_adult_excessively_cluttered_important_to_save: boolean;

    q15_adult_unmotivated_2_weeks: boolean;
    q47_adult_unmotivated_lost_interest: boolean;

    q13_adult_problematically_happy: boolean;
    q48_adult_felt_overly_confident: boolean;
    q49_adult_felt_extremely_irritable: boolean;

    q21_adult_avoid_eating_due_to_weight: boolean;
    q50_adult_avoid_eating_other_things: boolean;

    q23_adult_eat_very_little: boolean;
    q51_adult_eat_very_little_eating_habits: boolean;

    q7_adult_socially_anxious: boolean;
    q52_adult_socially_anxious_when_people_observe: boolean;

    q8_adult_panic_attack: boolean;
    q53_adult_panic_attack_sudden_rush: boolean;
    q54_adult_panic_attack_come_without_reason: boolean;

    q10_adult_worry_about_many_things: boolean;
    q55_adult_worry_excessive: boolean;
    q56_adult_worry_anxiety_cause_concerns: boolean;

    q3_adult_worry_appearance: boolean;
    q59_adult_worry_appearance_spend_time_doing_things: boolean;

    q5_adult_hair_pulling: boolean;
    q60_adult_hair_pulling_visible_hair_loss: boolean;

    q25_adult_worry_of_serious_medical_illness: boolean;
    q61_adult_worry_of_serious_medical_illness_get_alarmed: boolean;

    q9_adult_fearful_of_escape: boolean;
    q11_adult_afraid_of_objects_situations_activities: boolean;
    q12_adult_afraid_to_be_away_from_a_person: boolean;
    q14_adult_depressed_2_years: boolean;
    q16_adult_women_only_really_depressed_during_period: boolean;
    q17_adult_event_trauma: boolean;
    q18_adult_hard_time_with_stressful_experience: boolean;
    q19_adult_strange_beliefs: boolean;
    q20_adult_sensory_experience: boolean;
    q22_adult_eating_binges: boolean;
    q24_adult_physical_health: boolean;
    q28_adult_difficulty_paying_attn: boolean;
    q29_adult_difficult_sitting_still: boolean;

    q57_adult_physical_health_interfered: boolean;
    q58_adult_physical_health_excessive_worry: boolean;

    q62_adult_event_trauma_experience_incidents: boolean;
    q63_adult_depressed_2_years_occur_on_more_days: boolean;
    q64_adult_eating_binges_behaviors_prevent_weight_gain: boolean;
    q67_adult_eating_binges_frequency: boolean;

    q65_adult_frequent_thoughts_description: boolean;
    q65_adult_frequent_thoughts_description_other: boolean;

    q66_adult_repetitive_behaviors_description: boolean;
    q66_adult_repetitive_behaviors_description_other: boolean;
  } | null;
  is_diamond_adult_reviewed?: number;
}

export interface MemberAppointmentDiamondYouthForm {
  completed: number | null;
  created_at?: string;
  id?: number;
  member_appointments_id?: number;
  protocol_type?: string;
  q1_youth_frequent_thoughts: number;
  q1_youth_frequent_thoughts_positive: number;
  q1_youth_frequent_thoughts_details: string;
  q2_youth_repetitive_behaviors: number;
  q2_youth_repetitive_behaviors_positive: number;
  q2_youth_repetitive_behaviors_details: string;
  q3_youth_worry_appearance: number;
  q3_youth_worry_appearance_positive: number;
  q3_youth_worry_appearance_details: string;
  q4_youth_excessively_cluttered: number;
  q4_youth_excessively_cluttered_positive: number;
  q4_youth_excessively_cluttered_details: string;
  q5_youth_hair_pulling: number;
  q5_youth_hair_pulling_positive: number;
  q5_youth_hair_pulling_details: string;
  q6_youth_skin_picking: number;
  q6_youth_skin_picking_positive: number;
  q6_youth_skin_picking_details: string;
  q7_youth_refuse_to_speak: number;
  q7_youth_refuse_to_speak_positive: number;
  q7_youth_refuse_to_speak_details: string;
  q8_youth_embarassed_around_others: number;
  q8_youth_embarassed_around_others_positive: number;
  q8_youth_embarassed_around_others_details: string;
  q9_youth_uncomfortable_feelings: number;
  q9_youth_uncomfortable_feelings_positive: number;
  q9_youth_uncomfortable_feelings_details: string;
  q10_youth_fearful_of_escape: number;
  q10_youth_fearful_of_escape_positive: number;
  q10_youth_fearful_of_escape_details: string;
  q11_youth_worry_about_many_things: number;
  q11_youth_worry_about_many_things_positive: number;
  q11_youth_worry_about_many_things_details: string;
  q12_youth_afraid_of_objects_situations_activities: number;
  q12_youth_afraid_of_objects_situations_activities_positive: number;
  q12_youth_afraid_of_objects_situations_activities_details: string;
  q13_youth_afraid_to_be_away_from_a_person: number;
  q13_youth_afraid_to_be_away_from_a_person_positive: number;
  q13_youth_afraid_to_be_away_from_a_person_details: string;
  q14_youth_problematically_happy: number;
  q14_youth_problematically_happy_positive: number;
  q14_youth_problematically_happy_details: string;
  q15_youth_depressed_2_years: number;
  q15_youth_depressed_2_years_positive: number;
  q15_youth_depressed_2_years_details: string;
  q16_youth_unmotivated_2_weeks: number;
  q16_youth_unmotivated_2_weeks_positive: number;
  q16_youth_unmotivated_2_weeks_details: string;
  q17_youth_temper: number;
  q17_youth_temper_positive: number;
  q17_youth_temper_details: string;
  q18_youth_women_only_really_depressed_during_period: number;
  q18_youth_women_only_really_depressed_during_period_positive: number;
  q18_youth_women_only_really_depressed_during_period_details: string;
  q19_youth_event_trauma: number;
  q19_youth_event_trauma_positive: number;
  q19_youth_event_trauma_details: string;
  q20_youth_hard_time_with_stressful_experience: number;
  q20_youth_hard_time_with_stressful_experience_positive: number;
  q20_youth_hard_time_with_stressful_experience_details: string;
  q21_youth_strange_beliefs: number;
  q21_youth_strange_beliefs_positive: number;
  q21_youth_strange_beliefs_details: string;
  q22_youth_sensory_experience: number;
  q22_youth_sensory_experience_positive: number;
  q22_youth_sensory_experience_details: string;
  q23_youth_avoid_eating_due_to_weight: number;
  q23_youth_avoid_eating_due_to_weight_positive: number;
  q23_youth_avoid_eating_due_to_weight_details: string;
  q24_youth_eating_binges: number;
  q24_youth_eating_binges_positive: number;
  q24_youth_eating_binges_details: string;
  q25_youth_eat_very_little: number;
  q25_youth_eat_very_little_positive: number;
  q25_youth_eat_very_little_details: string;
  q26_youth_physical_health: number;
  q26_youth_physical_health_positive: number;
  q26_youth_physical_health_details: string;
  q27_youth_worry_of_serious_medical_illness: number;
  q27_youth_worry_of_serious_medical_illness_positive: number;
  q27_youth_worry_of_serious_medical_illness_details: string;
  q28_youth_alcohol: number;
  q28_youth_alcohol_positive: number;
  q28_youth_alcohol_details: string;
  q29_youth_drug_abuse: number;
  q29_youth_drug_abuse_positive: number;
  q29_youth_drug_abuse_details: string;
  q30_youth_difficulty_paying_attn: number;
  q30_youth_difficulty_paying_attn_positive: number;
  q30_youth_difficulty_paying_attn_details: string;
  q31_youth_difficult_sitting_still: number;
  q31_youth_difficult_sitting_still_positive: number;
  q31_youth_difficult_sitting_still_details: string;
  q32_youth_spastic_movements: number;
  q32_youth_spastic_movements_positive: number;
  q32_youth_spastic_movements_details: string;
  q33_youth_hurt_people: number;
  q33_youth_hurt_people_positive: number;
  q33_youth_hurt_people_details: string;
  q34_youth_feel_angry_or_cranky: number;
  q34_youth_feel_angry_or_cranky_positive: number;
  q34_youth_feel_angry_or_cranky_details: string;
  q35_youth_broken_law: number;
  q35_youth_broken_law_positive: number;
  q35_youth_broken_law_details: string;
  session_type?: string;
  updated_at?: string;
  user_id?: number;
  // v2 questions
  q36_youth_frequent_thoughts_details_text?: string;
  q37_youth_frequent_thoughts_come_up?: number;
  q38_youth_frequent_thoughts_bother?: number;

  q39_youth_repetitive_behaviors_stop_doing?: number;
  q40_youth_repetitive_behaviors_afraid?: string;

  q41_youth_alcohol_anyone_said?: number;
  q42_youth_alcohol_caused_problems?: number;

  q43_youth_drug_abuse_anyone_said?: number;
  q44_youth_drug_abuse_caused_problems?: number;

  q45_youth_hair_pulling_hair_is_all_gone?: number;

  q46_youth_skin_picking_scars?: number;

  q47_youth_spastic_movements_some_body_part?: number;
  q48_youth_spastic_movements_multiple_body_parts?: number;
  q49_youth_spastic_movements_making_sounds?: number;

  q50_youth_excessively_cluttered_past_month?: number;
  q51_youth_excessively_cluttered_others_throw_away?: number;
  q52_youth_excessively_cluttered_important_to_save?: number;

  q53_youth_event_trauma_led_intrusive_thoughts?: number;

  q54_youth_depressed_2_years_or_more?: number;
  q55_youth_depressed_2_years_feel_most?: number;

  q56_youth_unmotivated_2_weeks_lost_interest?: number;

  q57_youth_problematically_happy_elevated_mood?: number;
  q58_youth_problematically_happy_felt_powerful?: number;
  q59_youth_problematically_happy_cranky_mood?: number;

  q60_youth_avoid_eating_due_to_weight_actions?: number;

  q61_youth_eat_very_little_friends_family_worried?: number;

  q62_youth_refuse_to_speak_caused_problem?: number;

  q63_youth_embarassed_around_others_avoid_situations?: number;

  q64_youth_uncomfortable_feelings_occurred_for_no_reason?: number;

  q65_youth_worry_about_many_things_more_than_others?: number;
  q66_youth_worry_about_many_things_feel_tense?: number;

  q67_youth_feared_experiences_bring_anxiety?: number;
  q68_youth_fear_is_above_and_beyond?: number;

  q69_youth_afraid_to_be_away_from_person_caused_problems?: number;

  q70_youth_worry_medical_illness_done_avoide_things?: number;

  q71_youth_physical_health_caused_problems?: number;

  q72_youth_worry_appearance_spent_time?: number;

  q73_youth_temper_outbursts?: number;
  q74_youth_temper_angry_most_of_the_day?: number;

  q75_youth_difficulty_paying_attn_caused_problems?: number;
  q76_youth_difficulty_paying_attn_problems_six_months?: number;

  q77_youth_difficult_sitting_still_caused_problems?: number;
  q78_youth_difficult_sitting_still_last_six_months?: number;

  q79_youth_hurt_people_make_plans?: number;

  q80_youth_feel_angry_or_cranky_lost_temper?: number;

  q81_youth_frequent_thoughts_description?: string[];
  q81_youth_frequent_thoughts_description_other: string;
  q82_youth_repetitive_behaviors_description?: string[];
  q82_youth_repetitive_behaviors_description_other: string;

  // v3 questions diagnostic_assessment
  q1_youth_frequent_thoughts_diagnostic_assessment?: string;
  q2_youth_repetitive_behaviors_diagnostic_assessment?: string;
  q3_youth_worry_appearance_diagnostic_assessment?: string;
  q4_youth_excessively_cluttered_diagnostic_assessment?: string;
  q5_youth_hair_pulling_diagnostic_assessment?: string;
  q6_youth_skin_picking_diagnostic_assessment?: string;
  q7_youth_refuse_to_speak_diagnostic_assessment?: string;
  q8_youth_embarassed_around_others_diagnostic_assessment?: string;
  q9_youth_uncomfortable_feelings_diagnostic_assessment?: string;
  q10_youth_fearful_of_escape_diagnostic_assessment?: string;
  q11_youth_worry_about_many_things_diagnostic_assessment?: string;
  q12_youth_afraid_of_objects_diagnostic_assessment?: string;
  q13_youth_afraid_to_be_away_from_a_person_diagnostic_assessment?: string;
  q14_youth_problematically_happy_diagnostic_assessment?: string;
  q15_youth_depressed_2_years_diagnostic_assessment?: string;
  q16_youth_unmotivated_2_weeks_diagnostic_assessment?: string;
  q17_youth_temper_diagnostic_assessment?: string;
  q19_youth_event_trauma_diagnostic_assessment?: string;
  q20_youth_hard_time_with_stressful_exp_diagnostic_assessment?: string;
  q21_youth_strange_beliefs_diagnostic_assessment?: string;
  q22_youth_sensory_experience_diagnostic_assessment?: string;
  q23_youth_avoid_eating_due_to_weight_diagnostic_assessment?: string;
  q24_youth_eating_binges_diagnostic_assessment?: string;
  q25_youth_eat_very_little_diagnostic_assessment?: string;
  q26_youth_physical_health_diagnostic_assessment?: string;
  q27_youth_worry_of_serious_medical_illness_diagnostic_assessment?: string;
  q28_youth_alcohol_diagnostic_assessment?: string;
  q29_youth_drug_abuse_diagnostic_assessment?: string;
  q30_youth_difficulty_paying_attn_diagnostic_assessment?: string;
  q31_youth_difficult_sitting_still_diagnostic_assessment?: string;
  q32_youth_spastic_movements_diagnostic_assessment?: string;
  q33_youth_hurt_people_diagnostic_assessment?: string;
  q34_youth_feel_angry_or_cranky_diagnostic_assessment?: string;
  q35_youth_broken_law_diagnostic_assessment?: string;
  youth_proxy_balance_of_will_assess?: string;
  member_answered?: {
    q1_youth_frequent_thoughts: boolean;
    q36_youth_frequent_thoughts_details_text: boolean;
    q37_youth_frequent_thoughts_come_up: boolean;
    q38_youth_frequent_thoughts_bother: boolean;

    q2_youth_repetitive_behaviors: boolean;
    q39_youth_repetitive_behaviors_stop_doing: boolean;
    q40_youth_repetitive_behaviors_afraid: boolean;

    q28_youth_alcohol: boolean;
    q41_youth_alcohol_anyone_said: boolean;
    q42_youth_alcohol_caused_problems: boolean;

    q29_youth_drug_abuse: boolean;
    q43_youth_drug_abuse_anyone_said: boolean;
    q44_youth_drug_abuse_caused_problems: boolean;

    q21_youth_strange_beliefs: boolean;

    q22_youth_sensory_experience: boolean;

    q5_youth_hair_pulling: boolean;
    q45_youth_hair_pulling_hair_is_all_gone: boolean;

    q6_youth_skin_picking: boolean;
    q46_youth_skin_picking_scars: boolean;

    q32_youth_spastic_movements: boolean;
    q47_youth_spastic_movements_some_body_part: boolean;
    q48_youth_spastic_movements_multiple_body_parts: boolean;
    q49_youth_spastic_movements_making_sounds: boolean;

    q4_youth_excessively_cluttered: boolean;
    q50_youth_excessively_cluttered_past_month: boolean;
    q51_youth_excessively_cluttered_others_throw_away: boolean;
    q52_youth_excessively_cluttered_important_to_save: boolean;

    q19_youth_event_trauma: boolean;
    q53_youth_event_trauma_led_intrusive_thoughts: boolean;

    q15_youth_depressed_2_years: boolean;
    q54_youth_depressed_2_years_or_more: boolean;
    q55_youth_depressed_2_years_feel_most: boolean;

    q16_youth_unmotivated_2_weeks: boolean;
    q56_youth_unmotivated_2_weeks_lost_interest: boolean;

    q14_youth_problematically_happy: boolean;
    q57_youth_problematically_happy_elevated_mood: boolean;
    q58_youth_problematically_happy_felt_powerful: boolean;
    q59_youth_problematically_happy_cranky_mood: boolean;

    q23_youth_avoid_eating_due_to_weight: boolean;
    q60_youth_avoid_eating_due_to_weight_actions: boolean;

    q24_youth_eating_binges: boolean;

    q25_youth_eat_very_little: boolean;
    q61_youth_eat_very_little_friends_family_worried: boolean;

    q7_youth_refuse_to_speak: boolean;
    q62_youth_refuse_to_speak_caused_problem: boolean;

    q8_youth_embarassed_around_others: boolean;
    q63_youth_embarassed_around_others_avoid_situations: boolean;

    q9_youth_uncomfortable_feelings: boolean;
    q64_youth_uncomfortable_feelings_occurred_for_no_reason: boolean;

    q10_youth_fearful_of_escape: boolean;

    q11_youth_worry_about_many_things: boolean;
    q65_youth_worry_about_many_things_more_than_others: boolean;
    q66_youth_worry_about_many_things_feel_tense: boolean;

    q12_youth_afraid_of_objects_situations_activities: boolean;
    q67_youth_feared_experiences_bring_anxiety: boolean;
    q68_youth_fear_is_above_and_beyond: boolean;

    q13_youth_afraid_to_be_away_from_a_person: boolean;
    q69_youth_afraid_to_be_away_from_person_caused_problems: boolean;

    q27_youth_worry_of_serious_medical_illness: boolean;
    q70_youth_worry_medical_illness_done_avoide_things: boolean;

    q26_youth_physical_health: boolean;
    q71_youth_physical_health_caused_problems: boolean;

    q3_youth_worry_appearance: boolean;
    q72_youth_worry_appearance_spent_time: boolean;

    q17_youth_temper: boolean;
    q73_youth_temper_outbursts: boolean;
    q74_youth_temper_angry_most_of_the_day: boolean;

    q20_youth_hard_time_with_stressful_experience: boolean;

    q30_youth_difficulty_paying_attn: boolean;
    q75_youth_difficulty_paying_attn_caused_problems: boolean;
    q76_youth_difficulty_paying_attn_problems_six_months: boolean;

    q31_youth_difficult_sitting_still: boolean;
    q77_youth_difficult_sitting_still_caused_problems: boolean;
    q78_youth_difficult_sitting_still_last_six_months: boolean;

    q33_youth_hurt_people: boolean;
    q79_youth_hurt_people_make_plans: boolean;

    q34_youth_feel_angry_or_cranky: boolean;
    q80_youth_feel_angry_or_cranky_lost_temper: boolean;

    q35_youth_broken_law: boolean;

    q81_youth_frequent_thoughts_description: boolean;
    q81_youth_frequent_thoughts_description_other: boolean;
    q82_youth_repetitive_behaviors_description: boolean;
    q82_youth_repetitive_behaviors_description_other: boolean;
  } | null;
  is_diamond_youth_reviewed?: number;
}

export interface MemberAddendum {
  id?: number;
  created_at?: string;
  is_deleted?: number;
  addendum?: string;
}
interface MemberAppointmentColumbiaRiskProtectiveFactors {
  // Risk factors
  risk_factors_activating_events: string[];
  risk_factors_treatment_history: string[];
  risk_factors_treatment_history_text?: string;
  risk_factors_clinical_status: string[];
  risk_factors_clinical_status_text?: string | null;
  risk_factors_access_to_leathal_methods?: string | null;
  // Protective factors
  protective_factors_internal: string[];
  protective_factors_internal_text?: string | null;
  protective_factors_external: string[];
  protective_factors_external_text?: string | null;
}
interface MemberAppointmentColumbiaHighRisk {
  ideation_details?: string;
  when_ideation_occur?: string;
  thoughts_frequency?: number;
  thoughts_duration?: number;
  thoughts_control?: number;
  things_that_help_stop_thoughts?: number;
  reasons_for_thoughts?: number;
  risk_level_score?: number;
}
interface MemberAppointmentRiskAssessment {
  death_details: string;
  death_details_text: string;
  death_intention: string;
  death_intention_text: string;
  death_plan: string;
  death_plan_text: string;
  death_preparation: string;
  death_preparation_text: string;
  death_thoughts: string;
  death_thoughts_text: string;
  deathwish: string;
  deathwish_text: string;
  homicidality: string[];
  risk_details: string;
  risk_details_history?: string;
  safety_env: string;
  safety_plan: string;
  safety_step1: string;
  safety_step2: string;
  safety_step3: string;
  safety_step4: string;
  safety_step5: string;
  self_injury: string[];
  suicidality: string[];
  // columbia v2
  deathwish_time?: string[];
  death_thoughts_time?: string[];
  death_plan_time?: string[];
  death_intention_time?: string[];
  death_details_time?: string[];
  death_preparation_time?: string[];
  most_severe_ideation?: string | null;
  most_severe_ideation_frequency?: number | null;
  // risk assessment v2
  suicidality_details?: string | null;
  suicidality_date?: string | null;
  self_injury_details?: string | null;
  self_injury_date?: string | null;
  homicidality_details?: string | null;
  homicidality_date?: string | null;
  newly_identified_risk?: string | null;
}

interface MemberAppointmentSuicidalBehaviorForm {
  id?: number;
  member_appointments_id?: number;
  protocol_type?: string;
  created_at?: string;
  updated_at?: string;
  // q1
  q1_prep_acts_nssi: string;
  q1_prep_acts_nssi_lifetime: string;
  q1_prep_acts_nssi_last_time: string;
  q1_prep_acts_nssi_date: string;

  q1_prep_acts_si: string;
  q1_prep_acts_si_lifetime: string;
  q1_prep_acts_si_last_time: string;
  q1_prep_acts_si_date: string;
  // q2
  q2_aborted_acts_nssi: string;
  q2_aborted_acts_nssi_lifetime: string;
  q2_aborted_acts_nssi_last_time: string;
  q2_aborted_acts_nssi_date: string;

  q2_aborted_acts_si: string;
  q2_aborted_acts_si_lifetime: string;
  q2_aborted_acts_si_last_time: string;
  q2_aborted_acts_si_date: string;
  q2_aborted_acts_med_damage: string;
  q2_aborted_acts_med_damage_text?: string;

  // q3
  q3_interrupted_acts_nssi: string;
  q3_interrupted_acts_nssi_lifetime: string;
  q3_interrupted_acts_nssi_last_time: string;
  q3_interrupted_acts_nssi_date: string;

  q3_interrupted_acts_si: string;
  q3_interrupted_acts_si_lifetime: string;
  q3_interrupted_acts_si_last_time: string;
  q3_interrupted_acts_si_date: string;
  q3_interrupted_acts_med_damage: string;
  q3_interrupted_acts_med_damage_text?: string;

  // q4
  q4_actual_acts_nssi: string;
  q4_actual_acts_nssi_lifetime: string;
  q4_actual_acts_nssi_last_time: string;
  q4_actual_acts_nssi_date: string;

  q4_actual_acts_si: string;
  q4_actual_acts_si_lifetime: string;
  q4_actual_acts_si_last_time: string;
  q4_actual_acts_si_date: string;
  q4_actual_acts_med_damage: string;
  q4_actual_acts_med_damage_text?: string;
}
export interface Transcript {
  End: string[];
  Text: string[];
  Start: string[];
  Speaker: string[];
}
export interface TranscriptNote {
  [timestamp: string]: {
    note: string;
    type: "draft" | "final" | "revision";
  };
}
export interface MemberAppointmentPostAssessmentSummary {
  therapist_comments: string | null;
  goals: Goal[];
}

export interface MemberAppointmentHomicidalRiskAssessment {
  reason_for_assessment: string | null;
  reason_for_assessment_other: string | null;

  reported_stated: string | null;

  therapist_observation: string | null;

  current_thoughts_of_harm: string | null;
  current_thoughts_of_harm_details: string | null;
  current_thoughts_of_harm_who: string | null;
  current_thoughts_of_harm_time: string | null;

  specific_plan: string | null;
  specific_plan_who: string | null;
  specific_plan_when: string | null;
  specific_plan_where: string | null;
  specific_plan_follow_through: string | null;

  past_history_of_violence: string | null;
  past_history_of_violence_details: string | null;

  consequences_of_violence: string | null;
  consequences_of_violence_details: string | null;

  environmental_risk_factors: string[] | null;
  environmental_risk_factors_other: string | null;

  protective_factors: string[] | null;
  protective_factors_other: string | null;

  risk_assessment_level: string | null;
}

export interface MemberAppointment {
  admd_visit_id?: number;
  amd_appt_backfill?: string;
  addendums?: MemberAddendum[];
  appointment_date?: string;
  appointment_state?: string;
  appointment_type?: string;
  autoinvoice_id?: number;
  bg_address_setter?: string;
  version?: number;
  bg_primary_support_people_yes_no?: string;
  bg_current_stressors?: string;
  bg_current_stressors_text?: string;
  bg_pertinent_info?: string;
  bg_diagnostics_form?: MemberAppointmentBGDiagnosticsForm;
  bg_diagnostics_id?: number;
  bg_close_family_relationships?: string;
  bg_followup_changes_to_doctor_visits?: string;
  bg_followup_changes_to_doctor_visits_text?: string;
  bg_followup_changes_to_medication?: string;
  bg_followup_changes_to_medication_text?: string;
  bg_reported_current_address?: string;
  bg_reported_current_state?: string;
  billing_complete?: number;
  billing_state?: string;
  booked_author?: string;
  cancellation_author?: string;
  cancellation_info?: string;
  cancellation_ts?: string;
  cancelled_via_reschedule?: number;
  claim_reference_id?: string;
  claim_status?: string;
  clinical_forms_dass21_id?: number;
  clinical_forms_diamond_adult_id?: number;
  clinical_forms_diamond_youth_id?: number;
  clinical_forms_docs_id?: number;
  clinical_forms_qles_id?: number;
  clinician_compensated?: string;
  clinician_email?: string;
  clinician_signed_email?: string;
  clinician_timezone?: string;
  completion_at?: string;
  completion_info?: string;
  created_at?: string;
  dass_anxiety?: number;
  dass_depression?: number;
  dass_stress?: number;
  diagnosed_with_ocd?: number;
  diamond_adult_form?: MemberAppointmentDiamondAdultForm;
  diamond_distress_score?: number;
  diamond_functional_score?: number;
  diamond_severity_score?: number;
  diamond_severity_score_max?: number;
  diamond_youth_form?: MemberAppointmentDiamondYouthForm;
  discharge?: number;
  docs_severity?: number;
  docs_contamination_score?: number;
  docs_responsibility_score?: number;
  docs_unacceptable_thoughts_score?: number;
  docs_symmetry_score?: number;
  end_time_in_iso8601_utc?: string;
  end_ts?: string;
  event_id?: string;
  // first_session_has_completed_diamond_severity is only needed for the second session to determine if we should allow the clinician to fill out the diamond severity questions
  // if the first session has completed diamond severity questions, then signal to the client not to render the diamond severity questions in the second session
  first_session_has_completed_diamond_severity?: number;
  gcal_id?: string;
  has_forms?: number;
  has_scores?: string;
  id?: number;
  medical_additional_comments?: string;
  medical_json?: {
    medical_json: {
      speech: string[];
      cognition: string[];
      mood_affect: string[];
      danger_to_self: string[];
      thought_content: string[];
      thought_process: string[];
      danger_to_others: string[];
      insight_judgement: string[];
      appearance_behavior: string[];
    };
  };
  medical_within_normal_limits?: string | number;
  member?: Member;
  member_takeaway_notes?: string;
  needs_audit?: number;
  needs_billing_review?: number;
  needs_billing_review_notes?: string;
  needs_clinical_review?: number;
  needs_clinical_review_notes?: string;
  needs_review_notes?: string;
  next_step?: string;
  notes?: string;
  override_cpt?: string;
  override_cpt_by?: string;
  protocol_type?: string;
  rescheduled_at?: string;
  rescheduled_info?: string;
  rescheduled_review_notes?: string;
  rescheduled_signed_email?: string;
  rescheduled_to?: number;
  risk_assessment_json?: {
    risk_assessment: MemberAppointmentRiskAssessment;
  };
  previous_risk_assessment_json?: {
    risk_assessment: MemberAppointmentRiskAssessment;
  };
  previous_mse_note?: string | null;
  session_length?: string;
  session_type?: SessionTypes | SessionTypesV2 | SessionTypesV3;
  sign_note_session_length?: string;
  start_time_in_iso8601_utc?: string;
  start_ts?: string;
  successfully_completed_and_billable?: number;
  summary_appropriate_for_nocd?: string;
  provisional_referral_needed?: string;
  summary_end_time?: string;
  primary_icd_code?: string;
  provisional_icd_code_json?: string[];
  secondary_icd_codes?: string[];
  confirm_icd_codes_correct?: boolean;
  summary_icd_json?: string[];
  summary_icd_specifiers_text?: string;
  summary_not_continuing_nocd_plan?: string;
  summary_not_continuing_nocd_text?: string;
  summary_present_json?: string[];
  summary_present_other?: string;
  summary_private_notes?: string;
  summary_private_notes_rich?: {
    time: number;
    blocks: {
      id?: string;
      type: string;
      data: {
        text?: string;
        level?: number;
        style?: string;
        items?: string[];
        [key: string]: any;
      };
    }[];
    version: string;
  };
  summary_start_time?: string;
  summary_treatment_goals?: string[];
  summary_treatment_progress?: number;
  summary_treatment_response?: string;
  summary_types_of_treatment_erp?: number;
  summary_types_of_treatment_hrt?: number;
  summary_types_of_treatment_other?: string;
  user_id?: number;
  zoom_id?: string;
  zoom_join_link?: string;
  zoom_password?: string;
  zoom_meeting_link?: string;
  note_version?: string;
  canadian_masters_level?: number;
  canadian_diagnostic_info?: string;
  canadian_province?: string;
  forms?: {
    id?: number;
    completed_at?: string;
    form_fields_data?: { [key: string]: number | string };
    form_name?: string;
    form_scores_data?: { [key: string]: number | string };
    is_completed?: number;
    is_deleted?: number;
    user_id?: number;
  }[];
  therapy_case_type?: string;
  therapy_case_type_details?: string;
  previous_session_therapy_case_type?: string;
  needs_supervisor_review?: number;
  requested_supervisor?: string;
  supervisor_review_status?: SupervisorAppointmentNoteReviewStatus | null;
  is_initial_assessments_created?: number;
  previous_treatment_goals?: {
    summary_treatment_goals?: string[];
    summary_types_of_treatment_erp?: number;
    summary_types_of_treatment_hrt?: number;
    summary_types_of_treatment_other?: string;
  };
  is_assessments_refused_to_complete?: number;
  assessments_refused_to_complete_notes?: string;
  is_will_assess_count_disabled?: number;
  will_assess_count_disabled_notes?: string;
  can_disable_will_assess_count?: boolean;
  scheduling_frequency?: string;
  sched_protocol_compliant?: number;
  review_notes?: SupervisorReviewNote[];
  has_uploaded_parental_agreement?: number;
  is_parental_agreement_verified?: number;
  show_med_management_questions?: boolean;
  interested_in_medication_referral?: number;
  type_of_medication_referral?: string;
  medication_referral_settings_preference?: string;
  medication_referral_metro_area?: string | null;
  medication_referral_notes?: string;
  requires_psychotic_treatment?: number;
  actively_suicidal?: number;
  psych_hospital_in_last_six_months?: number;
  member_has_medicaid?: number;
  medication_referral_preferred_times?: {
    id: string;
    day: string;
    timeOfDay: string;
  }[];
  external_referral_recommended?: boolean;
  show_external_referral_recommended_questions?: boolean;
  talk_about_psychiatric_consultation?: string;
  talk_about_psychiatric_consultation_text?: string;
  therapist_would_like_to_refer_member?: string;
  therapist_would_like_to_refer_member_text?: string;
  agree_to_increase_scheduling_frequency?: string;
  agree_to_increase_scheduling_frequency_text?: string;
  transcripts?: Transcript[];
  transcript_notes?: TranscriptNote;
  transcript_status?: string;
  note_status?: string;
  num_speakers?: number;
  original_trace_id?: string;
  diamond_adult_prev_assessment_count?: number;
  diamond_youth_prev_assessment_count?: number;
  diamond_adult_current_assessment_count?: number;
  diamond_youth_current_assessment_count?: number;
  is_confirmed_diamond_assessment?: string | number;
  is_reviewed_summary_private_notes?: number;
  previous_scheduling_frequency?: string;
  needs_diagnosis_approval?: boolean;
  diagnosis_approval_status?: string;
  send_post_assessment_summary?: 0 | 1 | null;
  post_assessment_summary?: MemberAppointmentPostAssessmentSummary | null;
  last_post_assessment_summary?: {
    appointment_id: number;
    sent_at: string;
    primary_icd_code: string;
  };
  needs_post_assessment_summary_sent?: boolean;
  needs_post_assessment_summary_sent_reason?: string | null;
  needs_treatment_plan_goals?: boolean;
  needs_treatment_plan_goals_reason?: string | null;
  treatment_plan_version?: number | null;
  columbia_risk_protective_factors?: MemberAppointmentColumbiaRiskProtectiveFactors;
  columbia_high_risk?: MemberAppointmentColumbiaHighRisk;
  suicidal_behavior_form?: MemberAppointmentSuicidalBehaviorForm;
  history_of_short_sessions?: {
    short_sessions_count: number;
    has_history_of_short_sessions: boolean;
  };
  homicidal_risk_assessment?: MemberAppointmentHomicidalRiskAssessment | null;
  risk_stratification_flag?: {
    risk_level: "High" | "Moderate" | "Low";
  };
  // Medicaid Care Coordination
  attempted_care_coordination?: string;
  attempted_care_coordination_providers?: string[] | null;
  attempted_care_coordination_other?: string | null;
  attempted_care_coordination_reason?: string | null;

  is_next_session_exceeding_days?: number | null;
  next_session_exceeding_days_reason?: string | null;
}

// Member Contact Notes
export interface MemberContactNoteRiskAssessment {
  suicidality?: string[];
  homicidality?: string[];
  self_injury?: string[];
  risk_details?: string;
  risk_details_history?: string;
  deathwish?: string;
  deathwish_text?: string;
  death_thoughts?: string;
  death_thoughts_text?: string;
  death_plan?: string;
  death_plan_text?: string;
  death_intention?: string;
  death_intention_text?: string;
  death_details?: string;
  death_details_text?: string;
  death_preparation?: string;
  death_preparation_text?: string;
  safety_plan?: string;
  safety_plan_text?: string;
}
export interface MemberContactNote {
  id?: number;
  user_id?: number;
  contact_at?: string;
  contact_name?: string;
  clinician_email?: string;
  contacted_party_relationship?: string;
  contact_method?: string;
  contact_reason?: string;
  contact_details?: string;
  time_spent_in_mins?: number | string;
  risk_assessment_json?: null;
}

// Member Interview Forms
export interface MemberTraumaInterviewForm {
  id?: number;
  user_id?: number;
  q1_trauma_details: string;
  q2_other_traumatic_events: string;
  q3_target_trauma: string;
  q4_remember_feeling: string;
  q5_feel_helpless: string;
  q6_who_was_assailant: string[];
  q7_authority_figure_details: string;
  q8_assailant_other: string;
  q9_assailant_unknown: string;
  q10_where_trauma_occur: string[];
  q11_where_trauma_occur_other: string;
  q12_physical_injuries: string;
  q13_medical_attention: string;
  q14_legal_action_resulted: string;
  q15_blame_for_trauma: string[];
  q16_blame_for_trauma_other: string;
  q17_blame_for_trauma_details: string;
  q18_feeling_guilty: string;
  q19_current_physical_health: string;
  q20_health_problems: string;
  q21_support_system: string;
  q22_mood_details: string;
  q23_deliberately_harm_yourself: string;
  q24_sought_psychiatric_help: string;
  q25_sought_psychiatric_help_details: string;
  q26_been_hospitalised: string;
  q27_been_hospitalised_details: string;
  q28_prescription_medications: string;
  q29_street_drugs: string;
  q30_over_the_counter_medications: string;
  q31_alcohol_consumption: string;
  q32_drug_legal_social_problems: string;
  q33_consider_to_have_drug_problem: string;
  q34_life_trauma_summary_details: string;
  updated_at?: string;
  created_at?: string;
  completed?: number;
  is_deleted?: number;
}

interface ValuesAssessment {
  rating: number | null;
  kind_of_person_would_like_to_be: string | null;
  how_have_you_been_doing_in_last_month: string | null;
  some_specific_goals: string | null;
}
export interface TargetsInterviewActivities {
  id?: string | null;
  context: string | null;
}

export interface MemberActivationTargetsInterviewForm {
  id?: number;
  user_id?: number;
  routine_activities: null | TargetsInterviewActivities[];
  enjoyable_activities: null | TargetsInterviewActivities[];
  family_values: ValuesAssessment | null;
  partner_values: ValuesAssessment | null;
  friends_values: ValuesAssessment | null;
  religion_values: ValuesAssessment | null;
  meaningful_work: ValuesAssessment | null;
  education_values: ValuesAssessment | null;
  cultural_roots: ValuesAssessment | null;
  recreation_values: ValuesAssessment | null;
  volunteer_activity: ValuesAssessment | null;
  physical_health_values: ValuesAssessment | null;
  security_and_safety: ValuesAssessment | null;
  life_organization: ValuesAssessment | null;
  life_before_became_depressed: string | null;
  life_if_not_depressed: string | null;
  hours_of_sleep: string | null;
  take_naps: string | null;
  getting_up_fairly_consistent: string | null;
  typical_day_in_eating: string | null;
  types_of_foods_been_eating: string | null;
  changed_eating_habits_since_depression: string | null;
  daily_schedule_different_since_depression: string | null;
  consistent_problems_or_difficulties: string | null;
  other_activities_that_improve_mood: string | null;
  updated_at?: string;
  created_at?: string;
  completed?: number;
  is_deleted?: number;
}

export interface FunctionalAssessmentValues {
  fear_cues: null | string;
  fear_expectancies: null | string;
  safety_behavior: null | string;
  function_of_safety_behavior: null | string;
  id?: string | null;
}

export interface MemberFunctionalAssessment {
  id?: number;
  user_id?: number;
  external_or_environmental_triggers: FunctionalAssessmentValues[] | null;
  internal_triggers: FunctionalAssessmentValues[] | null;
  intrusive_mental_content: FunctionalAssessmentValues[] | null;
  updated_at?: string;
  created_at?: string;
  completed?: number;
  is_deleted?: number;
}

interface TreatmentInterferingBehaviorValues {
  primary_question: null | number;
  contributing_factors: null | string[];
  contributing_factors_other?: null | string;
  goal: null | string;
  objective: null | string;
  note: null | string;
  id?: string | null;
}

export interface MemberTreatmentInterferingBehavior {
  id?: number;
  user_id?: number;
  q1_not_acknowledge_problem: TreatmentInterferingBehaviorValues | null;
  q2_not_acknowledge_severity_impact: TreatmentInterferingBehaviorValues | null;
  q3_not_identify_treatment_goals: TreatmentInterferingBehaviorValues | null;
  q4_argues_with_treatment_team: TreatmentInterferingBehaviorValues | null;
  q5_change_focus_of_sessions: TreatmentInterferingBehaviorValues | null;
  q6_difficulty_explaining_treatment_plan: TreatmentInterferingBehaviorValues | null;
  q7_difficult_answering_questions: TreatmentInterferingBehaviorValues | null;
  q8_frequently_late_or_no_show: TreatmentInterferingBehaviorValues | null;
  q9_difficulty_following_treatment_plan: TreatmentInterferingBehaviorValues | null;
  q10_provides_inaccurate_information: TreatmentInterferingBehaviorValues | null;
  q11_engaging_in_self_destructive_acts: TreatmentInterferingBehaviorValues | null;
  q12_makes_team_feel_physically_threatened: TreatmentInterferingBehaviorValues | null;
  q13_other: string | null;
  updated_at?: string;
  created_at?: string;
  completed?: number;
  is_deleted?: number;
}

interface MemberCompletedAssignments {
  has_completed_since_last_session: boolean;
  completed_count: number;
}
export interface MemberCompletedAssignmentsSinceLastSession {
  journal_entries: MemberCompletedAssignments;
  sos_episodes: MemberCompletedAssignments;
  exercises_entries: MemberCompletedAssignments;
}

interface ReminderEmailValues {
  id?: number;
  recpeints?: string[];
  recieved_at?: string;
  subject?: string;
  status?: string;
  message_stream?: string;
  tag?: string;
}

// Member Profile
export interface MemberTherapyConsent {
  deleted_at?: string;
  deleted_by?: string;
  first_parent_name?: string;
  first_parent_email?: string;
  first_parent_phone?: string;
  second_parent_name?: string;
  second_parent_email?: string;
  second_parent_phone?: string;
  parent_legal_category?: string;
  is_secondary_consent_required?: number;
  enrollment_agreement_accepted_at?: string;
  enrollment_agreement_relationship?: string;
  enrollment_agreement_signature_base64?: string;
  patient_name?: string;
  patient_date_of_birth?: string;
  user_id?: number;
  first_parent_signature?: string;
  id: number;
  emergency_contact_name?: string;
  emergency_contact_phone?: string;
  emergency_contact_relation?: string;
}

export interface MemberNOCD411Consent {
  accepted_at: string;
  signature: string;
  user_id: number;
  created_at: string;
}

export interface MemberOCICVRConsentForm {
  accepted: number;
  signature: string;
  user_id: number;
  created_at: string;
}

export interface MemberNoticeOfPrivacyPractices {
  deleted_at?: string;
  deleted_by?: string;
  accepted_at: string;
  signature: string;
  user_id: number;
  created_at: string;
}

export interface MemberSupportPlanAttestation {
  id: number;
  user_id: number;
  accepted_at: string;
  signature: string;
  created_at: string;
}

interface MemberReleaseOfInformationDetails {
  roi_details_dates?: string;
  roi_details_diagnosis?: string;
  roi_details_all_of_the_above?: string;
  roi_details_discharge_notes?: string;
  roi_details_education?: string;
  roi_details_medical_info?: string;
  roi_details_messaging?: string;
  roi_details_notes?: string;
  roi_details_other?: string;
  roi_details_outcomes?: string;
  roi_details_status?: string;

  roi_details_messaging_my_therapist?: boolean;
  roi_details_hierarchy?: boolean;
  // V2 fields
  roi_details_full_record?: boolean;
  roi_details_full_record_excluding?: boolean;
  roi_details_full_record_excluding_text?: string | null;
  roi_details_other_records?: boolean;
  roi_purpose_continuity_of_care?: boolean;
  roi_purpose_request_individual?: boolean;
  roi_purpose_other?: boolean;
  roi_purpose_other_text?: string | null;
}

export interface MemberRelease {
  id?: string;
  user_id?: number;
  patient_dob?: string;
  patient_name?: string;
  roi_address?: string;
  roi_details?: MemberReleaseOfInformationDetails;
  roi_email?: string;
  roi_fax?: string;
  roi_name?: string;
  roi_organization?: string;
  roi_phone?: string;

  roi_signature_base64?: string;
  approved_by_clinician_at?: string | null;
  approved_by_clinician_id?: number | null;
  linked_account_id?: number | null;
  updated_at?: string | null;
  completed_at?: string;
  relation_to_patient?: string;
  signer_relation_to_patient?: string;
  expiration_date?: string;
  created_at?: string;
  created_by?: string | null;
  deleted_at?: string | null;
  is_deleted?: number | null;
  deleted_by?: string | null;
  deleted_reson?: string | null;
}

export type MembersRelease = MemberRelease[];

export interface MemberABNMedicare {
  id: number;
  user_id: number;
  therapy_option: string;
  additional_info: string | null;
  signature: string;
  accepted_at: string;
  created_at: string;
  updated_at: string | null;
  is_deleted: number | null;
}

interface MemberSubtypes {
  Subtypes?: string;
}

export type MembersSubtypes = MemberSubtypes[];

export interface MemberClinicianNotes {
  clinician_notes?: string;
  notes?: string;
}

export interface MemberClinicalScratchpad {
  user_id: number;
  clinical_scratchpad: string;
}

export interface AWSFileContent {
  ETag: string;
  Key: string;
  LastModified: Date | string;
  Size: number;
  StorageClass: string;
}

export interface MemberDischargeNote {
  id?: number;
  user_id?: number;
  created_at?: string;
  discharge_at?: string;
  discharge_barriers_and_notes?: string;
  discharge_care_and_referrals?: string;
  discharge_reason?: string;
  adhered_to_treatment_plan?: string;
  clinician_email?: string;
  is_deleted?: number;
  updated_by?: string;
  updated_at?: string;
  status?: DischargeRequestStatus;
  reviewer_notes?: string | null;
  manager_email?: string | null;
  discharge_note_id?: number | string | null;
}

interface Credential {
  plan_manager?: string;
  managed_insurances?: string;
  state?: string;
  credentialing_status?: string;
  credentialing_live_date?: string;
  license_status?: string;
  state_license?: string;
  insurance_state?: string;
}

export type Credentials = Credential[];

export interface MemberPastAppointmentNotes {
  id?: number;
  start_time_in_iso8601_utc?: string;
  session_type?: string;
  summary_private_notes?: string;
  clinician_email?: string;
}

export enum ClinicianEmploymentStatus {
  FULL_TIME = "Fulltime",
  PART_TIME = "Parttime",
  CONTRACT = "Contract",
}

export interface ClinicianInsuranceDetails {
  credentialing_status: string;
  credentialing_live_date?: string;
  state: string;
  plan_manager: string;
  provider_type?: "supervisor_as_rendering" | "independently_licensed";
}

interface PremiumClinicianFlag {
  id: number;
  clinician_id: number;
  flag_name: string;
  created_by: string | null;
  created_at: string;
  updated_by: string | null;
  updated_at: string | null;
}

export interface PremiumClinician {
  email: string;
  phone_number: string;
  clinician_timezone: string;
  zoom_meeting_link: string;
  use_new_messaging: boolean;
  user_id: number;
  gender: string;
  understanding_conditions: string[];
  understanding_conditions_other: string;
  understanding_cultural_identity_groups: string[];
  understanding_cultural_identity_groups_other: string;
  understanding_religious_affiliations: string[];
  understanding_religious_affiliations_other: string;
  understanding_lgbtq_plus: string;
  languages_spoken: {
    [key: string]: string;
  };
  languages_spoken_other: string;
  use_vacation_responder: number;
  vacation_responder_message: string;
  employment_status: ClinicianEmploymentStatus;
  needs_supervisor_review?: boolean;
  associate_supervisor?: string;
  has_seen_homework_builder_overview: boolean;
  roles: EhrRoles[];
  first_name: string;
  last_name: string;
  title: string;
  image_id: string;
  can_member_reschedule_session: boolean;
  can_member_schedule_session: boolean;
  can_member_cancel_session: boolean;
  permissions: string[];
  uses_scheduling_team: number;
  ignore_gcal_events: number;
  require_approval_for_schedule: number;
  insurance_details: ClinicianInsuranceDetails[];
  current_status: string;
  preferred_first_name: string;
  preferred_first_name_input: string;
  protocol_preferences?: {
    first: string;
    second: string;
    third: string;
  } | null;
  conquerer_achievement_count: number;
  need_diagnosis_approval: boolean;
  diagnosis_approver: string;
  current_clinician_role?: string | null;
  cancellation_detection_setting: string | null;
  flags: PremiumClinicianFlag[];
  opt_in_transcript_retention: boolean;
}

export interface MemberScript {
  id?: string;
  title?: string;
  body?: string;
  exercise_tool_id?: string;
  trigger?: string;
  triggerID?: string;
}

export enum MessageReactionTypes {
  LIKE = "like",
  HELPFUL = "helpful",
  HOPEFUL = "good_vibes",
  LAUGH = "laugh",
  SAD = "sad",
  TRIGGERING = "triggering",
}

export interface MessageReaction {
  count: number;
  current_user_reacted: boolean;
  type: MessageReactionTypes;
}

interface MessageButton {
  enabled: boolean;
  id: string;
  selected: boolean;
  selected_at: string;
  text: string;
  text_color: string;
}

export interface ThreadScheduled {
  id: number;
  sent_by_user_type?: string;
  content: string;
  sent_by_user_id: number;
  chat_channel_id: number;
  scheduled_to_send_at: Date;
  attachment_data?: string;
  channel_user_id?: string;
  status: string;
}

export interface ThreadMessage {
  automated?: boolean;
  automated_label?: string;
  automated_message_type?: string;
  avatar?: string;
  avatar_url?: string;
  is_birthday?: boolean;
  chat_channel_id: number;
  content: string;
  created_at: string;
  data?: {
    aspect_ratio?: number;
    image_url?: string;
    url?: string;
    name?: string;
    type?: string;
    filename_description?: string;
    size_text?: string;
    attachments?: {
      id?: number;
      url: string;
      name: string;
      size_text: string;
      type: string;
    }[];
  };
  day?: string;
  first_name?: string;
  id: number;
  is_event?: false;
  reactions?: MessageReaction[];
  sent_by_user_id: number;
  sent_by_user_type?: string;
  sent_by_user_email?: string;
  time?: string;
  user_type?: string;
  buttons?: MessageButton[];
  priority?: number;
  is_text_match?: boolean;
  is_name_match?: boolean;
  is_manual_reminder_sent?: boolean;
}

export interface ThreadQueduedMessage {
  automated?: boolean;
  automated_label?: string;
  automated_message_type?: string;
  avatar?: string;
  avatar_url?: string;
  is_birthday?: boolean;
  chat_channel_id: number;
  content: string;
  created_at: string;
  data?: {
    aspect_ratio?: number;
    image_url?: string;
    url?: string;
    name?: string;
    type?: string;
    filename_description?: string;
    size_text?: string;
    attachments?: {
      id?: number;
      url: string;
      name: string;
      size_text: string;
      type: string;
    }[];
  };
  day?: string;
  first_name?: string;
  id: number;
  is_event?: false;
  reactions?: MessageReaction[];
  sent_by_user_id: number;
  sent_by_user_type?: string;
  sent_by_user_email?: string;
  time?: string;
  user_type?: string;
  buttons?: MessageButton[];
  scheduled_to_send_at: string | Date;
  status: string;
  is_text_match?: boolean;
  is_name_match?: boolean;
}

interface ThreadDraftMessage {
  content?: string | null;
  edited_at?: string | null;
  error?: Error | string;
}

interface ThreadParticipant {
  avatar?: string;
  avatar_url?: string;
  email?: string;
  user_type?: string;
  first_name: string;
  last_name: string;
  preferred_name?: string;
  seen_at?: string;
  user_id: number;
}

export interface LinkedThread {
  id: number;
  primary_user_id: number;
  linked_accounts_id: number;
  display_name: string;
  other_channel_users: ThreadParticipant[];
  pubnub_channel: string;
  messages: (ThreadMessage | ChannelWhisper)[];
  is_active_member: boolean;
  snooze_clinician_notifications: boolean;
  draft_message: ThreadDraftMessage;
  linked_accounts_label?: string;
  archived_at?: string;
  created_at?: string;
  is_locked?: boolean;
  image_url?: string;
  unread_count?: number;
  current_service?: string;
  diagnostic_timezone?: string;
  protocol_type?: string;
  phone_number?: string;
  email?: string;
  channel_metadata?: {
    linked_first_name?: string;
  };
  conqueror?: string;
  is_new_member?: boolean;
  is_transfer_member?: boolean;
  next_appointment_date?: string;
  is_text_match?: boolean;
  is_name_match?: boolean;
  implies_response?: boolean;
  latest_message_at?: string;
  is_birthday?: boolean;
  legal_name?: string;
  can_send_reminder_sms?: boolean;
  sms_enabled?: boolean;
  reminder_text_sent_at?: string;
}

export interface Thread {
  archived_at?: string;
  channel_metadata?: {
    linked_first_name?: string;
  };
  channel_type?: string;
  claimed_at?: string;
  claimed_by_first_name?: string;
  claimed_by_avatar?: string;
  created_at?: string;
  current_service?: string;
  day?: string;
  diagnostic_timezone?: string;
  display_name: string;
  draft_message: ThreadDraftMessage;
  email?: string;
  events?: ChatEvents[];
  id: number;
  is_active_member?: boolean;
  is_event?: boolean;
  is_locked?: boolean;
  image_url?: string;
  linked_threads: LinkedThread[];
  linked_accounts_id?: number;
  linked_accounts_label?: string;
  messages: (ThreadMessage | ChannelWhisper)[];
  other_channel_users: ThreadParticipant[];
  phone_number?: string;
  primary_user_id: number;
  protocol_type?: string;
  pubnub_channel: string;
  snooze_clinician_notifications: boolean;
  time?: string;
  unread_count?: number;
  locked_at?: string;
  conqueror?: string;
  is_new_member?: boolean;
  is_transfer_member?: boolean;
  next_appointment_date?: string;
  is_text_match?: boolean;
  is_name_match?: boolean;
  implies_response?: boolean;
  latest_message_at?: string;
  is_birthday?: boolean;
  legal_name?: string;
  can_send_reminder_sms?: boolean;
  sms_enabled?: boolean;
  reminder_text_sent_at?: string;
}

export interface ChatEvents {
  text: string;
  date: string;
  day: string;
  time: string;
  is_event: boolean;
  created_at: string;
  is_whisper: false;
}

export interface ContactMemberAdvocate {
  archived_at: string;
  avatar: string;
  avatar_url: string;
  can_prompt_trial_end_reminder: boolean;
  channel_id: number;
  channel_type: string;
  clinician_email: string;
  claimed_at: string;
  claimed_by_user_id: number;
  claimed_by_avatar_url: string;
  claimed_by_first_name: string;
  claimed_by_last_name: string;
  created_at: string;
  days_until_locked: number;
  display_name?: string;
  events?: ChatEvents[];
  first_name: string;
  is_locked: boolean;
  last_name: string;
  last_seen_by_advocate_at: string;
  last_seen_by_advocate_user_id: number;
  member_email: string;
  message_preview: string;
  member_is_high_risk: 0 | 1 | null;
  message_preview_sent_by: string;
  message_preview_time: string;
  messages?: MessageMemberAdvocate[];
  member_timezone: string;
  member_user_id: number;
  pending_at: string;
  preferred_name: string;
  pubnub_channel: string;
  unread_count: number;
  is_parent_linked_account: 0 | 1;
  is_child_linked_account: 0 | 1;
  latest_message_id?: number;
  latest_message_category?: string;
  latest_message_priority?: number;
  has_open_tickets?: boolean;
}

export interface OtherChannelUserMemberAdvocate {
  avatar_url?: string;
  avatar: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  seen_at: string;
  user_id: number;
  member_timezone?: string;
  member_clinician_email?: string;
}

export interface AdvocateContactSearch {
  showResolved: number;
  showUnresolved: number;
  showFreeTrialUsers: number;
  showTherapyMembers: number;
  searchKey: string;
  claimedByFilter: number[];
  showUnassigned: number;
  showRead: number;
  showUnread: number;
  showPending: number;
  showNonPending: number;
  showHighRisk: number;
  showOpenTickets: number;
}

export interface ClaimedByMenuItem {
  user_id: number;
  first_name?: string;
  last_name?: string;
  email: string;
}

interface ChannelUsersMemberAdvocate {
  avatar_url?: string;
  avatar: string;
  first_name: string;
  last_name: string;
  preferred_name: string;
  seen_at: string;
  user_id: number;
}

export interface MessageMemberAdvocate {
  id: number;
  automated: number;
  avatar: string;
  button_type: string;
  buttons?: MessageButton[];
  channel_clinician_user_id: number;
  channel_user_id: number;
  chat_channel_id: number;
  clinician_user_id: number;
  content: string;
  created_at: string;
  created_at_iso8601: string;
  first_name: string;
  is_deleted: number;
  member_user_id: number;
  sent_by_user_id: number;
  sent_by_user_type: string;
  channel_type: string;
  user_type: string;
  subscribed_message: number;
  trace_id: string;
  is_event: boolean;
  is_whisper: false;
  day: string;
  time: string;
  reactions?: MessageReaction[];
  data?: {
    image_url: string;
  };
}

export interface ConversationMemberAdvocate {
  id: number;
  messages: MessageMemberAdvocate[];
  other_channel_users: ChannelUsersMemberAdvocate[];
  primary_user_id: number;
  pubnub_channel: string;
  unread_count: number;
  updated_at: string;
  archived_at: string;
  archived_by: string;
  channel_type: string;
  created_at: string;
  deleted_at: string;
  deleted_by: string;
  pending_at: string;
  claimed_at: string;
  claimed_by_first_name: string;
  claimed_by_user_id: number;
  days_until_locked: number;
  is_locked: boolean;
  latest_intake_call_datetime?: string;
  latest_intake_call_status?: string;
}

export interface ChannelMessage {
  avatar: string;
  avatar_url?: string;
  channel_type: string;
  chat_channel_id?: number;
  content: string;
  created_at: string;
  first_name: string;
  id: number;
  sent_by_user_id: number;
  sent_by_user_type: string;
  user_type: string;
  user_email: string;
  buttons?: MessageButton[];
  priority?: number;
}

export interface LinkedAccountContact {
  messages?: [
    {
      content: string;
      created_at: string;
      sent_by_user_id: number;
      sent_by_user_type: string;
      user_type: string;
      first_name: string;
      avatar: string;
      avatar_url: string;
      buttons?: MessageButton[];
      priority?: number;
    }
  ];
  display_name: string;
  image_url: string;
  archived_at?: string;
  assigned_to_current_therapist: number;
  avatar_name?: string;
  avatar_type?: string;
  current_service?: string;
  custom_avatar_url?: string;
  diagnostic_timezone?: string;
  email?: string;
  first_name: string;
  id?: number;
  is_active_member: number;
  last_name: string;
  linked_accounts_id?: number;
  linked_accounts_label?: string;
  message_preview?: string;
  message_preview_time?: string;
  phone?: string;
  preferred_name?: string;
  pronouns?: string;
  unread_count: number;
  user_id?: number;
  primary_user_id?: number;
  pubnub_channel: string;
  other_channel_users?: ThreadParticipant[];
  locked_at?: string;
  is_read_only?: boolean;
  provider_user_id?: number;
}

export interface MemberAssessmentResults {
  dass: {
    form_scores_data: {
      dass_anxiety_score?: number;
      dass_depression_score?: number;
      dass_stress_score?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  docs: {
    form_scores_data: {
      docs_severity?: number;
      docs_symmetry_score?: number;
      docs_responsibility_score?: number;
      docs_contamination_score?: number;
      docs_unacceptable_thoughts_score?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  hrs: {
    form_scores_data: { score?: number };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  qles: {
    form_scores_data: {
      raw_score?: number;
      qles_score?: number;
      number_q_answered?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  rbfbs: {
    form_scores_data: {
      screen_a_repetitively_pick_skin?: number;
      screen_b_repetitively_pull_hair?: number;
      screen_c_repetitively_bite_nails?: number;
      screen_d_other_bfrb?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  tic: {
    form_scores_data: {
      aggregate_score?: number;
      motor_tics_aggregate_score?: number;
      vocal_tics_aggregate_score?: number;
    };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  whodas: {
    form_scores_data: { whodas_disability_score?: number };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  pcl5: {
    form_scores_data: { score?: number };
    id: number;
    start_ts: number;
    completed_at?: number;
  }[];
  diamond_severity: {
    form_scores_data: {
      distress_score?: string;
      functional_score?: string;
    };
    id: string;
    completed_at?: string;
  }[];
}

export interface IntakeCall {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  country_code: string;
  phone: string;
  preferred_name: string;
  state: string;
  dob: string;
  member_address: string;
  member_state: string;
  member_city: string;
  member_zipcode: string;
  what_prompted_you_to_reach_out_for_therapy_now: string;
  rp_name: string;
  rp_phone: string;
  rp_email: string;
  responsible_party_relation_to_patient: string;
  label: string;
  disposition_not_converting: string;
  member_user_id: number;
  user_id: number;
  call_outcome: string;
  not_converting_reason: string;
  experience_with_erp: string;
  what_information_do_you_want_from_your_call: string;
  distress_level: string;
  preferred_therapy_sessions_availability: string;
  special_requests: string;
  subtypes: string;
  therapist_preference_gender: string;
  therapist_preference_religious_affiliation: string;
  therapist_preference_cultural_identity: string;
  therapist_preference_additional_specialty: string;
  preferred_clinician_email: string;
  timezone: string;
  insurance_provider: string;
  peer_advisor_email: string;
  assigned_to: string;
  call_at: string;
  is_callback?: number;
  is_call_in?: number;
  is_deleted?: boolean;
  canceled?: boolean;
  stripe_customer_id?: string;
  scheduling_reason?: string;
  wants_move_up?: boolean;
  interested_in_sca?: 0 | 1;
  using_medicaid_or_medicare?: 0 | 1;
  is_linked_account: 0 | 1;
  is_root_account: 0 | 1;
  member_before_call: boolean;
  how_did_you_hear_about_nocd: string | null;
  hospitalized_recently: string | null;
  followup_care_recently: string | null;
  intent_self_harm_recently: string | null;
  current_intent_self_harm: string | null;
  hospital_discharge_date: string | null;
}

export interface IntakeNote {
  id: number;
  member_id: string;
  text: string;
  author: string;
  created_at: string;
  department: string;
}

export interface IntakeCallAssignmentRequest {
  intake_id: number;
  assigned_to: string;
  appointment_at: string;
  initials: string;
}

export interface IntakeAttachCreditCardRequest {
  customer_id: string;
  token: string;
  user_id: number;
  billing_name: string;
}

export interface IntakeSetDefaultPaymentMethodRequest {
  customer_id: string;
  pm_id: string;
}

export interface MemberPastMedication {
  id: number;
  medication_name: string;
  start_time_in_iso8601_utc: string;
  session_type: string;
}

export interface AssociateClinicianMember extends Member {
  user_id: number;
  clinician_email: string;
}

export interface MemberHealthcareProvider {
  id?: number;
  npi_number: string | null;
  npi_type: "organization" | "individual" | "not_set" | null;
  organization_name: string | null;
  first_name: string | null;
  last_name: string | null;
  address_line_1: string | null;
  address_line_2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
  phone: string | null;
  fax_number: string | null;
  specialty: string | null;
  title: string | null;
  source?: string;
  referred_member?: number | null;
  reason_working_with?: string | null;
  // For backwards compatibility
  name?: string | null;
}

export interface MemberPrimaryOutcomeForm {
  id: number;
  user_id: number;
  forms_table_id: number;
  name: string;
  forms_table_id_subscore: string | null;
}
interface MemberSubscoreOutcomeResult {
  score: number | null;
}

interface MemberPrimaryOutcomeResults {
  form_submissions_id: number;
  completed_at: string;
  score_outcome_name: string;
  lsas_total?: MemberSubscoreOutcomeResult;
  lsas_fear?: MemberSubscoreOutcomeResult;
  lsas_avoidance?: MemberSubscoreOutcomeResult;
  lsas_ca_total?: MemberSubscoreOutcomeResult;
  lsas_ca_fear?: MemberSubscoreOutcomeResult;
  lsas_ca_avoidance?: MemberSubscoreOutcomeResult;
  docs_contamination?: MemberSubscoreOutcomeResult;
  docs_responsibility?: MemberSubscoreOutcomeResult;
  docs_symmetry?: MemberSubscoreOutcomeResult;
  docs_thoughts?: MemberSubscoreOutcomeResult;
  hrs?: MemberSubscoreOutcomeResult;
  tic_aggregate?: MemberSubscoreOutcomeResult;
  tic_motor?: MemberSubscoreOutcomeResult;
  tic_vocal?: MemberSubscoreOutcomeResult;
  bfrb_bite_nails?: MemberSubscoreOutcomeResult;
  bfrb_pick_skin?: MemberSubscoreOutcomeResult;
  bfrb_pull_hair?: MemberSubscoreOutcomeResult;
  bfrb_other?: MemberSubscoreOutcomeResult;
  pcl5?: MemberSubscoreOutcomeResult;
  phq_9?: MemberSubscoreOutcomeResult;
  gad_7?: MemberSubscoreOutcomeResult;
  asi_3?: MemberSubscoreOutcomeResult;
  smspa?: MemberSubscoreOutcomeResult;
  smsada?: MemberSubscoreOutcomeResult;
  smaa?: MemberSubscoreOutcomeResult;
  shai?: MemberSubscoreOutcomeResult;
  biq?: MemberSubscoreOutcomeResult;
  mfq?: MemberSubscoreOutcomeResult;
  pswq_c?: MemberSubscoreOutcomeResult;
}

export interface MemberPrimaryOutcomeFormResults {
  outcomes: MemberPrimaryOutcomeResults[] | null;
  score_name: string;
  form_name: string;
  percent_change: number | null;
}

export interface Clinician {
  user_id: number;
  email: string;
  first_name: string | null;
  last_name: string | null;
  preferred_name: string | null;
  preferred_pronouns: string | null;
  title: string | null;
  image: string | null;
  image_id: string | null;
  timezone: string | null;
  employment_status: string | null;
  current_status: string | null;
  protocol_types: string[];
  min_age: number | null;
  hours_of_notice: number | null;
  committed_hours: number | null;
  marketing_profile_url: string | null;
  marketing_video_url: string | null;
  ocd_experience: string | null;
  about_me: string | null;
  can_member_schedule_session: boolean;
  can_member_reschedule_session: boolean;
  require_approval_for_schedule: boolean;
  ignore_gcal_events: boolean;
  uses_scheduling_team: boolean;
  scheduling_team_notes: string | null;
  accepting_transfers: string | null;
  clinical_supervisor: string | null;
  clinical_region: string | null;
}

export interface UtilizationOverview {
  clinician_quarter_utilization: WeekUtilization[];
  clinician_days_utilization: DayUtilization[];
  clinician_completed_utilization: CompletedUtilization[];
  clinician_late_cancelled_utilization: LateCancelledUtilization[];
  committed_hours: number;
  completed_sum_utilization: number;
  completed_weeks_total: number;
}

export interface WeekUtilization {
  completed_hours: number | null;
  scheduled_hours: number | null;
  late_cancelled_hours: number | null;
  pto_hours: number | null;
  utilization_credit: number | null;
  grant_hours: number | null;
  week_name: string | null;
  num_weeks: number;
  total_utilization: number;
  week_completed: 1 | 0;
  current_week: 1 | 0;
}

export interface DayUtilization {
  day: string | null;
  weeks_name: number | null;
  utilization_credit: number | null;
  completed_hours: number | null;
  late_cancelled_hours: number | null;
  pto_hours: number | null;
  grant_hours: number | null;
}
export interface CompletedUtilization {
  id: number | null;
  user_id: number | null;
  hours: number | null;
  ma_clinician_start_ts: string | null;
}
export interface LateCancelledUtilization {
  id: number | null;
  ma_id: number | null;
  user_id: number | null;
  session_type: number | null;
  hours: number | null;
  ma_clinician_start_ts: string | null;
  counts_toward_utilization_credit: number | null;
}
