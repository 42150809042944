import { sign as signJWT } from "jsonwebtoken";

import { Unit } from "./types";

const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  // Force the formatted value to be an integer
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatValue = (value: number | string, unit: Unit) => {
  if (value == null) {
    return "N/A";
  }

  if (unit === "string") {
    return value;
  }

  if (unit === "usd") {
    return usdFormatter.format(value as number);
  }

  if (unit === "percentage") {
    return `${value}%`;
  }

  if (unit === "stars") {
    return `${value} stars`;
  }

  if (unit === "hours") {
    return `${value} hrs`;
  }

  return value.toString();
};

const METABASE_SITE_URL = process.env.NEXT_PUBLIC_METABASE_SITE_URL;
const METABASE_SECRET_KEY = process.env.NEXT_PUBLIC_METABASE_SECRET_KEY;

export enum MetabaseDashboard {
  // 2025 Q1
  CURRENT_STATS_ASSESSMENT_COMPLETION_2025_Q1 = 428,
  CURRENT_STATS_CHARTS_CLOSED_2025_Q1 = 431,
  CURRENT_STATS_OUTCOMES_2025_Q1 = 434,
  CURRENT_STATS_SEEN_AT_THERAPIST_ASSIGNED_FREQUENCY_2025_Q1 = 436,
  CURRENT_STATS_SCHEDULED_TO_THERAPIST_ASSIGNED_FREQUENCY_2025_Q1 = 437,
  CURRENT_STATS_AVERAGE_WEEKLY_UTILIZATION_2025_Q1 = 429,
  CURRENT_STATS_TREATMENT_ADHERENCE_V6_2025_Q1 = 440,
  CURRENT_STATS_TREATMENT_ADHERENCE_V18_2025_Q1 = 439,
  CURRENT_STATS_TREATMENT_ADHERENCE_12_SESSIONS_2025_Q1 = 383,
  CURRENT_STATS_TREATMENT_CONQUERORS_2025_Q1 = 441,
  CURRENT_STATS_SUSTAINED_CONQUERORS_2025_Q1 = 438,
  CURRENT_STATS_CANCELLATION_RESCHEDULE_RATE_2025_Q1 = 430,
  CURRENT_STATS_MESSAGING_FIRST_SESSION_FOLLOW_UP_2025_Q1 = 433,
  CURRENT_STATS_MESSAGING_RESPONSIVENESS_2025_Q1 = 435,
  CURRENT_STATS_MESSAGING_FREQUENCY_2025_Q1 = 432,
}

export const goToMetabaseDashboard = (
  dashboard: MetabaseDashboard,
  params: Record<string, string | number>
) => {
  const payload = {
    resource: { dashboard },
    params,
    exp: Math.round(Date.now() / 1000) + 30 * 60, // 30 mins
  };

  const token = signJWT(payload, METABASE_SECRET_KEY);

  window.open(
    `${METABASE_SITE_URL}/embed/dashboard/${token}#titled=false&bordered=false`
  );
};
