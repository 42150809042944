import { useSession } from "@core/hooks/useSession";
import { AxiosError } from "axios";
import { isNil } from "lodash/fp";
import useSWR, { SWRResponse } from "swr";
import invariant from "tiny-invariant";

import api from "./api";
import {
  APIError,
  AWSFileContent,
  Member,
  MemberABNMedicare,
  MemberAssessmentResults,
  MemberClinicianNotes,
  MemberContactNote,
  MemberDischargeNote,
  MemberNOCD411Consent,
  MemberNoticeOfPrivacyPractices,
  MemberOCICVRConsentForm,
  MemberPastAppointmentNotes,
  MembersRelease,
  MembersSubtypes,
  MemberSupportPlanAttestation,
  MemberTherapyConsent,
  SafetyPlan,
} from "./types";

export const updateMember = (
  userId: number | string,
  payload: Partial<Member>,
  accessToken: string | undefined
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

const getMember = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<Member> => {
  invariant(userId != null, "userId is required");

  return api
    .get<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const getMemberQueryKey = (
  userId: string | number | undefined,
  accessToken: string | undefined
) =>
  !isNil(accessToken) && !isNil(userId)
    ? ["member", userId, accessToken]
    : undefined;

// ONLY Therapist can use this hook
// Scheduling/Intake team can not use this hook
// it has role restrictions in the backend so it will break if used by scheduling/intake team
export const useMember = (
  userId: number | string | undefined
): SWRResponse<Member, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const swrProps = useSWR<Member, Error>(
    userId ? getMemberQueryKey(userId, accessToken) : null,
    () => getMember(userId, accessToken)
  );

  return swrProps;
};

export const updateMemberSafetyPlan = (
  userId: number | string,
  payload: SafetyPlan,
  accessToken: string | undefined
): Promise<SafetyPlan> =>
  api
    .post<SafetyPlan>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/safety_plan`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export interface PrimaryAddressPayload {
  address: string;
  city: string;
  state: string | null;
  zipcode: string;
  country_other: string | null;
  country_code: string;
  user_id: number;
}

export const updateMemberPrimaryAddress = (
  payload: PrimaryAddressPayload,
  accessToken: string | undefined
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(
        payload.user_id
      )}/update_primary_address`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const updateMemberContactNote = (
  userId: number | string,
  payload: MemberContactNote,
  accessToken: string | undefined
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/contact_notes`,
      {
        ...payload,
        contact_at: payload.contact_at
          ? new Date(payload.contact_at).toISOString()
          : undefined,
        action: payload.id ? "DELETE" : "ADD",
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const addMemberDischargeNote = (
  userId: number | string,
  payload: Partial<MemberDischargeNote>,
  accessToken: string | undefined,
  isUpdate: boolean,
  needsReview: boolean,
  existingDischargeNoteId?: number | string | null
): Promise<Member> =>
  api
    .post<Member>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/discharge_notes`,
      {
        ...payload,
        discharge_at: payload.discharge_at
          ? new Date(payload.discharge_at).toISOString()
          : undefined,
        is_update: isUpdate,
        needs_review: needsReview,
        existing_discharge_note_id: existingDischargeNoteId,
      },
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

const getMemberReleaseInformation = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MembersRelease> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MembersRelease>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/release_information`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => {
      return data;
    })
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberTherapyConsentForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberTherapyConsent> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberTherapyConsent>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/therapy_consent`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberTherapyConsentFormList = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberTherapyConsent[]> => {
  invariant(userId != null, "userId is required");
  return api
    .get<MemberTherapyConsent[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/therapy_consent_list`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberNOCD411ConsentForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberNOCD411Consent> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberNOCD411Consent>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/nocd411_consent`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberNoticeOfPrivacyPracticesForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberNoticeOfPrivacyPractices[]> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberNoticeOfPrivacyPractices[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/v2/notice_of_privacy_practices`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberOCICVRConsentForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberOCICVRConsentForm> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberOCICVRConsentForm>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/ocicvr_consent_form`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberSupportPlanAttestationForm = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberSupportPlanAttestation[]> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberSupportPlanAttestation[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/support_plan_attestation`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberSubtypes = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MembersSubtypes> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MembersSubtypes>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/subtypes`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

const getMemberClinicianNotes = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberClinicianNotes> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberClinicianNotes>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/clinician_notes`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

interface UseMemberProfileData {
  releaseInformation?: MembersRelease;
  therapyConsentForm?: MemberTherapyConsent;
  nocd411ConsentForm?: MemberNOCD411Consent;
  therapyConsentFormList?: MemberTherapyConsent[];
  noticeOfPrivacyPracticesForm?: MemberNoticeOfPrivacyPractices[];
  ocicvrConsentForm?: MemberOCICVRConsentForm;
  subtypes?: MembersSubtypes;
  clinicianNotes?: MemberClinicianNotes;
  abnConsentForm?: MemberABNMedicare[];
  supportPlanAttestationForm?: MemberSupportPlanAttestation[];
}

export function useMemberProfile(userId: number | string | undefined): {
  data: UseMemberProfileData | undefined;
  mutate: (newData: UseMemberProfileData) => void;
} {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const { data, mutate } = useSWR<UseMemberProfileData>(
    userId != null ? `member-profile-${userId}` : undefined,
    () =>
      Promise.all([
        getMemberReleaseInformation(userId, accessToken),
        getMemberTherapyConsentForm(userId, accessToken),
        getMemberTherapyConsentFormList(userId, accessToken),
        getMemberNOCD411ConsentForm(userId, accessToken),
        getMemberNoticeOfPrivacyPracticesForm(userId, accessToken),
        getMemberSubtypes(userId, accessToken),
        getMemberClinicianNotes(userId, accessToken),
        getMemberOCICVRConsentForm(userId, accessToken),
        getMemberSupportPlanAttestationForm(userId, accessToken),
      ]).then(
        ([
          releaseInformation,
          therapyConsentForm,
          therapyConsentFormList,
          nocd411ConsentForm,
          noticeOfPrivacyPracticesForm,
          subtypes,
          clinicianNotes,
          ocicvrConsentForm,
          supportPlanAttestationForm,
        ]) => ({
          releaseInformation,
          therapyConsentForm,
          therapyConsentFormList,
          nocd411ConsentForm,
          noticeOfPrivacyPracticesForm,
          subtypes,
          clinicianNotes,
          ocicvrConsentForm,
          supportPlanAttestationForm,
        })
      )
  );

  const mutateWithPartialUpdate = (newData: UseMemberProfileData) =>
    mutate({
      ...data,
      ...newData,
    });

  return {
    data: data
      ? {
          releaseInformation: data.releaseInformation,
          therapyConsentForm: data.therapyConsentForm,
          therapyConsentFormList: data.therapyConsentFormList,
          nocd411ConsentForm: data.nocd411ConsentForm,
          noticeOfPrivacyPracticesForm: data.noticeOfPrivacyPracticesForm,
          subtypes: data.subtypes,
          clinicianNotes: data.clinicianNotes,
          ocicvrConsentForm: data.ocicvrConsentForm,
          supportPlanAttestationForm: data.supportPlanAttestationForm,
        }
      : undefined,
    mutate: mutateWithPartialUpdate,
  };
}

const getMemberDocuments = (
  userId: string | number,
  directory: string,
  accessToken: string | undefined
): Promise<AWSFileContent[]> =>
  api
    .get<AWSFileContent[]>(`v2/clinician/members/${userId}/files`, {
      headers: accessToken
        ? {
            Authorization: accessToken,
          }
        : undefined,
      params: {
        directory,
      },
    })
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getMemberDocumentsQueryKey = (
  userId: string | number,
  accessToken: string | undefined
) => {
  return !isNil(userId) && !isNil(accessToken)
    ? ["clinical-files", userId, accessToken]
    : undefined;
};

export const useMemberDocuments = (userId: string | number) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const swrProps = useSWR<AWSFileContent[]>(
    getMemberDocumentsQueryKey(userId, accessToken),
    () => getMemberDocuments(userId, "Clinical", accessToken)
  );

  return swrProps;
};

export const getMemberPastAppointmentsNotes = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberPastAppointmentNotes[]> =>
  api
    .get<MemberPastAppointmentNotes[]>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/past_appointments_notes`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

const getMemberAssessmentResults = (
  userId: number | string,
  accessToken: string | undefined
): Promise<MemberAssessmentResults> =>
  api
    .get<MemberAssessmentResults>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/v2/assessment_results`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const useAssessmentResults = (userId: string | number) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const swrProps = useSWR(
    !isNil(userId) && !isNil(accessToken)
      ? ["assessment_results", userId, accessToken]
      : undefined,
    () => getMemberAssessmentResults(userId, accessToken)
  );

  return swrProps;
};
