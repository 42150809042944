import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useMutation, useQueryClient } from "react-query";

const useBulkOfferSlotsToMembers = () => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload: {
      clinician_email: string;
      slots: {
        user_id: number;
        slot: string;
        duration_in_mins: number;
        type: string;
      }[];
    }) =>
      api
        .post("/v1/scheduling/bulk_offer_slots_to_members", payload, {
          headers: {
            Authorization: accessToken,
          },
        })
        .catch(transformAPIError),
    {
      onSuccess: (_data, payload) =>
        queryClient.invalidateQueries([
          "member-finder",
          payload.clinician_email,
        ]),
    }
  );
};

export default useBulkOfferSlotsToMembers;
