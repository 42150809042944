import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useQuery, UseQueryResult } from "react-query";

import { ISimpleMetric } from "../../types";
import { goToMetabaseDashboard, MetabaseDashboard } from "../../utils";
import SimpleMetric from "../SimpleMetric";

export const useClinicianTreatmentConquerorsMetric = (
  clinicianEmail: string,
  timePeriod = "quarter"
): UseQueryResult<ISimpleMetric, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    [
      "clinician-current-stats",
      "treatment-conquerors",
      "2025-q1",
      timePeriod,
      clinicianEmail,
    ],
    ({ signal }) =>
      api
        .get<ISimpleMetric>(
          `/v1/clinicians/${clinicianEmail}/progress/2025/q1/current_stats/treatment_conquerors`,
          {
            headers: {
              Authorization: accessToken,
            },
            params: {
              period: timePeriod,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!accessToken && !!clinicianEmail,
      staleTime: Infinity,
      keepPreviousData: true,
    }
  );
};

const TreatmentConquerorsMetricV2 = ({
  clinicianEmail,
}: {
  clinicianEmail: string;
}) => {
  const [timePeriod, setTimePeriod] = useState<string>("quarter");

  const { data, isLoading, error, isRefetching } =
    useClinicianTreatmentConquerorsMetric(clinicianEmail, timePeriod);

  if (error) {
    return <SimpleMetric.Failure message={error?.message} />;
  }

  if (isLoading) {
    return <SimpleMetric.Loading />;
  }

  if (isEmpty(data)) {
    return null;
  }

  return (
    <SimpleMetric
      title={data.title}
      tooltipText={data.tooltip_text}
      timePeriod={timePeriod}
      onChangeTimePeriod={setTimePeriod}
      value={data.value}
      unit={data.unit}
      isLoading={isRefetching}
      onViewDetails={() =>
        goToMetabaseDashboard(
          MetabaseDashboard.CURRENT_STATS_TREATMENT_CONQUERORS_2025_Q1,
          {
            clinician_email: clinicianEmail,
          }
        )
      }
    />
  );
};

export default TreatmentConquerorsMetricV2;
