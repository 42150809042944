import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Modal, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useQuery, UseQueryOptions } from "react-query";

const useStyles = makeStyles(() => ({
  roundedBox: {
    backgroundColor: "#E6E6FA", // light purple background
    borderRadius: "12px", // rounded corners
    padding: "24px", // increased padding inside the box
    width: "100%", // full width
    display: "flex", // flex layout
    flexDirection: "column", // column layout to stack items vertically
    alignItems: "flex-start", // align items to the start (left)
    boxSizing: "border-box", // include padding in the element's total width and height
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // subtle shadow for depth
  },
  gradientHeading: {
    display: "flex",
    alignItems: "center",
    background: "linear-gradient(to right, #4b0082, #8b0000)", // Explicit color codes for dark purple to dark red
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "28px", // adjusted font size for better readability
    fontWeight: "bold",
    marginBottom: "16px",
  },
  icon: {
    marginRight: "8px",
    fontSize: "32px", // increased icon size for better visibility
  },
  bodyText: {
    textAlign: "left",
    width: "100%",
    lineHeight: "1.6",
    fontSize: "16px",
  },
}));

interface SessionRecapProps {
  appointment_id: number;
}

interface SessionRecapResponse {
  insights: string;
}

export const fetchSessionRecap = async (
  appointmentId: number,
  accessToken: string
): Promise<SessionRecapResponse> => {
  const { data } = await api.get(
    `/v1/therapy/members/session_recap/${appointmentId}`,
    {
      headers: {
        Authorization: accessToken,
      },
    }
  );
  return data;
};

export const useSessionRecap = (
  appointmentId: number,
  options?: UseQueryOptions<SessionRecapResponse, Error>
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery<SessionRecapResponse, Error>(
    ["session-recap", appointmentId, accessToken],
    () => fetchSessionRecap(appointmentId, accessToken),
    {
      enabled: appointmentId != null && !!accessToken,
      staleTime: Infinity,
      ...options,
    }
  );
};

const SessionRecap: React.FC<SessionRecapProps> = ({ appointment_id }) => {
  const classes = useStyles();
  const { data, error, isLoading } = useSessionRecap(appointment_id);

  if (isLoading) {
    return (
      <Box className={classes.roundedBox}>
        <Typography variant="h1" className={classes.gradientHeading}>
          <AutoAwesomeOutlinedIcon className={classes.icon} />
          Member Activity Since Last Session
        </Typography>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="90%" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.roundedBox}>
        <Typography variant="h1" className={classes.gradientHeading}>
          <AutoAwesomeOutlinedIcon className={classes.icon} />
          Member Activity Since Last Session
        </Typography>
        <Typography>Error loading session recap</Typography>
      </Box>
    );
  }

  // 3. Replace the skeleton with the loaded text from the API response
  return (
    <Box className={classes.roundedBox}>
      <Typography variant="h1" className={classes.gradientHeading}>
        <AutoAwesomeOutlinedIcon className={classes.icon} />
        Member Activity Since Last Session
      </Typography>
      <ReactMarkdown className={classes.bodyText}>
        {data?.insights || ""}
      </ReactMarkdown>
    </Box>
  );
};

const SessionRecapModal: React.FC<{ appointment_id: number }> = ({
  appointment_id,
}) => {
  const [open, setOpen] = useState(true);
  const [closing, setClosing] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOpen = () => {
    setOpen(true);
    setClosing(false);
  };

  const handleClose = () => {
    setClosing(true);

    // Wait for animation to complete before fully closing the modal
    setTimeout(() => {
      setOpen(false);
      setClosing(false);
    }, 500); // Match the animation duration
  };

  const modalStyle = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: closing
      ? "translate(150%, -50%)" // Move fully offscreen to the right
      : "translate(-50%, -50%)", // Centered
    width: "95%", // Increased width
    maxWidth: "1000px", // Increased maxWidth
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    maxHeight: "90vh",
    overflowY: "auto",
    transition: "transform 0.5s, opacity 0.5s",
    opacity: closing ? 0 : 1,
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        sx={{
          color: "#4050B5",
          textTransform: "uppercase",
          fontWeight: "500",
          fontSize: "14px",
          height: "32px",
          minWidth: "275px",
          padding: "0 24px",
          borderRadius: "22px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1.5,
          borderColor: "#4050B5",
          "&:hover": {
            backgroundColor: "transparent",
            borderColor: "#2F3C9E",
            color: "#2F3C9E",
          },
        }}
      >
        <AutoAwesomeOutlinedIcon fontSize="small" />
        View Session Insights
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          {/* Close Button */}
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              minWidth: "auto",
              padding: "8px",
              borderRadius: "50%",
              border: "1px solid",
              borderColor: "secondary.main",
              zIndex: 1301,
            }}
          >
            <CloseIcon fontSize="small" />
          </Button>
          {/* Content */}
          <Box sx={{ maxHeight: "90vh", overflowY: "auto" }}>
            <SessionRecap appointment_id={appointment_id} />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SessionRecapModal;
